import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../image/logo.gif";
import "./header.css";
import youtube from "../../image/youtube_logo.png";
import user from "../../image/user.jpg";
import { Icon, Segment, TransitionablePortal, Button } from "semantic-ui-react";
import Settings from "../settings/settings";
import Urls from "../urls/urls";

/**
 * Description : The class "Header" contains the top part of the webapp
 * it contain the logo, the tarification, the connexion and insction redirect button,
 * if there is a token, the header will be change and display account settings and dashboard button
 * State :<br/>
 *    token : contains the user token<br/>
 *    windowWidth : the window's width<br/>
 * Return : The header of the website<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.sessionStorage.getItem("token"),
      windowWidth: window.innerWidth,
      image: "",
      user_subscription: "",
    };
  }

  /**
   * function : getUserSubscription is used to get which subscription the user had
   * input :
   * output :
   */
  getUserSubscription = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/user-active-subscription/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.length === 0) {
            this.setState({ user_subscription: "Freemium" });
          } else {
            this.setState({ user_subscription: data[0].plan.product.name });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : toHomeYoutube is used to redirect user to home page.
   * input : none
   * output : none
   */
  toHomeYoutube = () => {
    window.location.href = Urls.urls_server + "#youtube_player1";
  };

  /**
   * function : toRedirect is used to redirect user according to id.
   * input : _id : id of the button
   * output : none
   */
  toRedirect = (_id) => {
    if (_id === 1) {
      window.location.href = Urls.urls_server + "";
    } else if (_id === 2) {
      window.location.href = Urls.urls_server + "tarif";
    } else if (_id === 3) {
      window.location.href = Urls.urls_server + "dashboard";
    } else if (_id === 4) {
      window.location.href = Urls.urls_server + "saisie";
    } else if (_id === 5) {
      window.location.href = Urls.urls_server + "transaction";
    }
  };

  /**
   * function : hoverActive is used to set hover on the right button.
   * input : none
   * output : none
   */
  hoverActive = () => {
    var url = location.href;
    url = url.replace(Urls.urls_server, "");
    if (this.state.windowWidth >= 600) {
      if (url === "dashboard") {
        if (this.state.user_subscription === "Freemium") {
          document
            .getElementsByClassName("tarif")[0]
            .style.setProperty("background-color", "transparent");
          document
            .getElementsByClassName("tarif")[0]
            .style.setProperty("color", "white");
        }
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("color", "white");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("color", "white");

        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("background-color", "#EEEEEE");
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("color", "black");
      }
      if (
        url === "tarif" &&
        this.state.token !== "" &&
        this.state.token !== null &&
        this.state.token !== "undefined"
      ) {
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("color", "white");
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("color", "white");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("color", "white");

        document
          .getElementsByClassName("tarif")[0]
          .style.setProperty("background-color", "#EEEEEE");
        document
          .getElementsByClassName("tarif")[0]
          .style.setProperty("color", "black");
      }
      if (url === "saisie") {
        if (this.state.user_subscription === "Freemium") {
          document
            .getElementsByClassName("tarif")[0]
            .style.setProperty("background-color", "transparent");
          document
            .getElementsByClassName("tarif")[0]
            .style.setProperty("color", "white");
        }
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("color", "white");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("color", "white");

        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("background-color", "#EEEEEE");
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("color", "black");
      }
      if (url === "transaction") {
        if (this.state.user_subscription === "Freemium") {
          document
            .getElementsByClassName("tarif")[0]
            .style.setProperty("background-color", "transparent");
          document
            .getElementsByClassName("tarif")[0]
            .style.setProperty("color", "white");
        }
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("color", "white");
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("color", "white");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("background-color", "#EEEEEE");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("color", "black");
      }
      if (url === "apiconnexion") {
        if (this.state.user_subscription === "Freemium") {
          document
            .getElementsByClassName("tarif")[0]
            .style.setProperty("background-color", "transparent");
          document
            .getElementsByClassName("tarif")[0]
            .style.setProperty("color", "white");
        }
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("dashboard")[0]
          .style.setProperty("color", "white");
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("saisie")[0]
          .style.setProperty("color", "white");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("background-color", "transparent");
        document
          .getElementsByClassName("transaction")[0]
          .style.setProperty("color", "white");
      }
    }
  };

  /**
   * this function will be execute as soon as the component will be displayed
   * it is used to update token every 500 milli second
   */
  componentDidMount() {
    setInterval(() => {
      this.setState({ token: window.sessionStorage.getItem("token") });
      this.setState({ windowWidth: window.innerWidth });
      this.hoverActive();
    }, 500);
    if (this.state.token !== null) {
      this.getUserSubscription();
    }
  }

  render() {
    /*case of computer screen and unconnected*/
    if (
      (this.state.token === "" ||
        this.state.token === null ||
        this.state.token === "undefined") &&
      this.state.windowWidth >= 600
    ) {
      return (
        <div id="header">
          <Link id="link" to="/">
            <img id="logo" src={logo} />
          </Link>
          <Link id="button_header" to="/">
            Accueil
          </Link>
          <Link id="button_header" to="/tarif">
            Tarifs
          </Link>
          <br />
          <br />
          <div onClick={this.toHomeYoutube}>
            <img id="logo_youtube" src={youtube} />
          </div>
          <div id="inscription_connexion">
            <Link id="button_header_signup" to="/inscription">
              Inscription
            </Link>
            <Link id="button_header_signin" to="/connexion">
              Connexion
            </Link>
          </div>
        </div>
      );
    } else if (
      /*case of smartphone screen and unconnected*/
      (this.state.token === "" ||
        this.state.token === null ||
        this.state.token === "undefined") &&
      this.state.windowWidth < 600
    ) {
      return (
        <div id="header_parent">
          <div id="header_smartphone">
            <Link id="link" to="/">
              <img id="logo" src={logo} />
            </Link>
            <div onClick={this.toHomeYoutube}>
              <img id="logo_youtube" src={youtube} />
            </div>
            <Link id="button_header_signup" to="/inscription">
              Inscription
            </Link>
            <Link id="button_header_signin" to="/connexion">
              Connexion
            </Link>
          </div>
          <div id="header_bottom">
            <div onClick={() => this.toRedirect(1)} id="button_smartphone_div">
              <Icon size="big" name="home" />
              <Link id="button_header" to="/">
                Accueil
              </Link>
            </div>
            <div onClick={() => this.toRedirect(2)} id="button_smartphone_div">
              <Icon size="big" name="euro sign" />
              <Link id="button_header" to="/Tarif">
                Tarifs
              </Link>
            </div>
          </div>
        </div>
      );
    } else if (
      /*case of smartphone screen and connected with email and not freemium*/
      (this.state.token !== "" ||
        this.state.token !== null ||
        this.state.token !== "undefined") &&
      this.state.windowWidth < 600 &&
      window.sessionStorage.getItem("photo") === null &&
      this.state.user_subscription !== "Freemium"
    ) {
      return (
        <div id="header_parent">
          <div id="header_smartphone">
            <Link id="link" to="/">
              <img id="logo" src={logo} />
            </Link>
            <div onClick={this.toHomeYoutube}>
              <img id="logo_youtube" src={youtube} />
            </div>
            <Link to="/settings">
              <Button id="button_header_popup">
                <img id="logo_user" src={user} />
              </Button>
            </Link>
          </div>
          <div id="header_bottom">
            <div onClick={() => this.toRedirect(3)} id="button_smartphone_div">
              <Icon size="big" name="area graph" />
              <Link id="button_header" to="/dashboard">
                Tableau
                <br /> de bord
              </Link>
            </div>
            <div onClick={() => this.toRedirect(4)} id="button_smartphone_div">
              <Icon size="big" name="keyboard" />
              <Link id="button_header" to="/saisie">
                Saisie
              </Link>
            </div>
            <div onClick={() => this.toRedirect(5)} id="button_smartphone_div">
              <Icon size="big" name="percent" />
              <Link id="button_header" to="/transaction">
                Tx / €
              </Link>
            </div>
          </div>
        </div>
      );
    } else if (
      /*case of smartphone screen and connected with email and freemium*/
      (this.state.token !== "" ||
        this.state.token !== null ||
        this.state.token !== "undefined") &&
      this.state.windowWidth < 600 &&
      window.sessionStorage.getItem("photo") === null &&
      this.state.user_subscription === "Freemium"
    ) {
      return (
        <div id="header_parent">
          <div id="header_smartphone">
            <Link id="link" to="/">
              <img id="logo" src={logo} />
            </Link>
            <Link id="button_header_top" to="/tarif">
              Tarifs
            </Link>
            <div onClick={this.toHomeYoutube}>
              <img id="logo_youtube" src={youtube} />
            </div>
            <Link to="/settings">
              <Button id="button_header_popup">
                <img id="logo_user" src={user} />
              </Button>
            </Link>
          </div>
          <div id="header_bottom">
            <div onClick={() => this.toRedirect(3)} id="button_smartphone_div">
              <Icon size="big" name="area graph" />
              <Link id="button_header" to="/dashboard">
                Tableau
                <br /> de bord
              </Link>
            </div>
            <div onClick={() => this.toRedirect(4)} id="button_smartphone_div">
              <Icon size="big" name="keyboard" />
              <Link id="button_header" to="/saisie">
                Saisie
              </Link>
            </div>
            <div onClick={() => this.toRedirect(5)} id="button_smartphone_div">
              <Icon size="big" name="percent" />
              <Link id="button_header" to="/transaction">
                Tx / €
              </Link>
            </div>
          </div>
        </div>
      );
    } else if (
      /*case of smartphone screen and connected with google and not freemium*/
      (this.state.token !== "" ||
        this.state.token !== null ||
        this.state.token !== "undefined") &&
      this.state.windowWidth < 600 &&
      window.sessionStorage.getItem("photo") !== null &&
      this.state.user_subscription !== "Freemium"
    ) {
      return (
        <div id="header_parent">
          <div id="header_smartphone">
            <Link id="link" to="/">
              <img id="logo" src={logo} />
            </Link>
            <div onClick={this.toHomeYoutube}>
              <img id="logo_youtube" src={youtube} />
            </div>
            <Link to="/settings">
              <Button id="button_header_popup">
                <img
                  id="logo_user"
                  src={window.sessionStorage.getItem("photo")}
                />
              </Button>
            </Link>
          </div>
          <div id="header_bottom">
            <div onClick={() => this.toRedirect(3)} id="button_smartphone_div">
              <Icon size="big" name="area graph" />
              <Link id="button_header" to="/dashboard">
                Tableau
                <br /> de bord
              </Link>
            </div>
            <div onClick={() => this.toRedirect(4)} id="button_smartphone_div">
              <Icon size="big" name="keyboard" />
              <Link id="button_header" to="/saisie">
                Saisie
              </Link>
            </div>
            <div onClick={() => this.toRedirect(5)} id="button_smartphone_div">
              <Icon size="big" name="percent" />
              <Link id="button_header" to="/transaction">
                Tx / €
              </Link>
            </div>
          </div>
        </div>
      );
    } else if (
      /*case of smartphone screen and connected with google and freemium*/
      (this.state.token !== "" ||
        this.state.token !== null ||
        this.state.token !== "undefined") &&
      this.state.windowWidth < 600 &&
      window.sessionStorage.getItem("photo") !== null &&
      this.state.user_subscription === "Freemium"
    ) {
      return (
        <div id="header_parent">
          <div id="header_smartphone">
            <Link id="link" to="/">
              <img id="logo" src={logo} />
            </Link>
            <Link id="button_header_top" to="/tarif">
              Tarifs
            </Link>
            <div onClick={this.toHomeYoutube}>
              <img id="logo_youtube" src={youtube} />
            </div>
            <Link to="/settings">
              <Button id="button_header_popup">
                <img
                  id="logo_user"
                  src={window.sessionStorage.getItem("photo")}
                />
              </Button>
            </Link>
          </div>
          <div id="header_bottom">
            <div onClick={() => this.toRedirect(3)} id="button_smartphone_div">
              <Icon size="big" name="area graph" />
              <Link id="button_header" to="/dashboard">
                Tableau
                <br /> de bord
              </Link>
            </div>
            <div onClick={() => this.toRedirect(4)} id="button_smartphone_div">
              <Icon size="big" name="keyboard" />
              <Link id="button_header" to="/saisie">
                Saisie
              </Link>
            </div>
            <div onClick={() => this.toRedirect(5)} id="button_smartphone_div">
              <Icon size="big" name="percent" />
              <Link id="button_header" to="/transaction">
                Tx / €
              </Link>
            </div>
          </div>
        </div>
      );
    } else if (
      /*case of computer screen and connected with email and not freemium*/
      (this.state.token !== "" ||
        this.state.token !== null ||
        this.state.token !== "undefined") &&
      this.state.windowWidth < 600 &&
      window.sessionStorage.getItem("photo") === null &&
      this.state.user_subscription !== "Freemium"
    ) {
      return (
        <div id="header">
          <Link id="link" to="/">
            <img id="logo" src={logo} />
          </Link>
          <Link id="button_header" className="dashboard" to="/dashboard">
            Tableau de bord
          </Link>
          <Link id="button_header" className="saisie" to="/saisie">
            Saisie plateformes et Cryptos
          </Link>
          <Link id="button_header" className="transaction" to="/transaction">
            Transaction EURO / CRYPTO
          </Link>
          <br />
          <br />
          <div onClick={this.toHomeYoutube}>
            <img id="logo_youtube" src={youtube} />
          </div>
          <TransitionablePortal
            closeOnTriggerClick
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            transition={{
              ...{ animation: "slide left" },
              ...{ duration: 500 },
            }}
            openOnTriggerClick
            trigger={
              <Button id="button_header_popup">
                <img id="logo_user" src={user} />
              </Button>
            }
          >
            <Segment
              id="segment"
              style={{
                right: "0",
                position: "fixed",
                top: "0",
                zIndex: 1000,
                overflow: "auto",
                maxHeight: "100vh",
                width: "40vw",
              }}
            >
              <Settings />
            </Segment>
          </TransitionablePortal>
        </div>
      );
    } else if (
      /*case of computer screen and connected with email and freemium*/
      (this.state.token !== "" ||
        this.state.token !== null ||
        this.state.token !== "undefined") &&
      this.state.windowWidth >= 600 &&
      window.sessionStorage.getItem("photo") === null &&
      this.state.user_subscription === "Freemium"
    ) {
      return (
        <div id="header">
          <Link id="link" to="/">
            <img id="logo" src={logo} />
          </Link>
          <Link id="button_header" className="tarif" to="/tarif">
            Tarifs
          </Link>
          <Link id="button_header" className="dashboard" to="/dashboard">
            Tableau de bord
          </Link>
          <Link id="button_header" className="saisie" to="/saisie">
            Saisie plateformes et Cryptos
          </Link>
          <Link id="button_header" className="transaction" to="/transaction">
            Transaction EURO / CRYPTO
          </Link>
          <br />
          <br />
          <div onClick={this.toHomeYoutube}>
            <img id="logo_youtube" src={youtube} />
          </div>
          <TransitionablePortal
            closeOnTriggerClick
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            transition={{
              ...{ animation: "slide left" },
              ...{ duration: 500 },
            }}
            openOnTriggerClick
            trigger={
              <Button id="button_header_popup">
                <img id="logo_user" src={user} />
              </Button>
            }
          >
            <Segment
              id="segment"
              style={{
                right: "0",
                position: "fixed",
                top: "0",
                zIndex: 1000,
                overflow: "auto",
                maxHeight: "100vh",
                width: "40vw",
              }}
            >
              <Settings />
            </Segment>
          </TransitionablePortal>
        </div>
      );
    } else if (
      /*case of computer screen and connected with google and freemium*/
      (this.state.token !== "" ||
        this.state.token !== null ||
        this.state.token !== "undefined") &&
      this.state.windowWidth >= 600 &&
      window.sessionStorage.getItem("photo") !== null &&
      this.state.user_subscription === "Freemium"
    ) {
      return (
        <div id="header">
          <Link id="link" to="/">
            <img id="logo" src={logo} />
          </Link>
          <Link id="button_header" className="tarif" to="/tarif">
            Tarifs
          </Link>
          <Link id="button_header" className="dashboard" to="/dashboard">
            Tableau de bord
          </Link>
          <Link id="button_header" className="saisie" to="/saisie">
            Saisie plateformes et Cryptos
          </Link>
          <Link id="button_header" className="transaction" to="/transaction">
            Transaction EURO / CRYPTO
          </Link>
          <br />
          <br />
          <div onClick={this.toHomeYoutube}>
            <img id="logo_youtube" src={youtube} />
          </div>
          <TransitionablePortal
            closeOnTriggerClick
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            transition={{
              ...{ animation: "slide left" },
              ...{ duration: 500 },
            }}
            openOnTriggerClick
            trigger={
              <Button id="button_header_popup">
                <img
                  id="logo_user"
                  src={window.sessionStorage.getItem("photo")}
                />
              </Button>
            }
          >
            <Segment
              id="segment"
              style={{
                right: "0",
                position: "fixed",
                top: "0",
                zIndex: 1000,
                overflow: "auto",
                maxHeight: "100vh",
                width: "40vw",
              }}
            >
              <Settings />
            </Segment>
          </TransitionablePortal>
        </div>
      );
    } else {
      /*case of computer screen and connected with google and not freemium*/
      return (
        <div id="header">
          <Link id="link" to="/">
            <img id="logo" src={logo} />
          </Link>
          <Link id="button_header" className="dashboard" to="/dashboard">
            Tableau de bord
          </Link>
          <Link id="button_header" className="saisie" to="/saisie">
            Saisie plateformes et Cryptos
          </Link>
          <Link id="button_header" className="transaction" to="/transaction">
            Transaction EURO / CRYPTO
          </Link>
          <br />
          <br />
          <div onClick={this.toHomeYoutube}>
            <img id="logo_youtube" src={youtube} />
          </div>
          <TransitionablePortal
            closeOnTriggerClick
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            transition={{
              ...{ animation: "slide left" },
              ...{ duration: 500 },
            }}
            openOnTriggerClick
            trigger={
              <Button id="button_header_popup">
                <img
                  id="logo_user"
                  src={window.sessionStorage.getItem("photo")}
                />
              </Button>
            }
          >
            <Segment
              id="segment"
              style={{
                right: "0",
                position: "fixed",
                top: "0",
                zIndex: 1000,
                overflow: "auto",
                maxHeight: "100vh",
                width: "40vw",
              }}
            >
              <Settings />
            </Segment>
          </TransitionablePortal>
        </div>
      );
    }
  }
}
