import React, { Component } from "react";
import "./mail.css";

/**
 * DESCRIPTION : The class "Mail" diplay a infomation message about a email sent<br/><br/>
 * RETURN : <br/>
 * Mail alert page
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Mail extends Component {
  render() {
    return (
      <div id="mail_message">
        <h1>
          Un mail vous a été envoyé, Veuillez confirmer en cliquant sur le lien.
        </h1>
      </div>
    );
  }
}
