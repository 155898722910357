import React, { Component, useLayoutEffect } from "react";
import Urls from "../urls/urls";
import Recap from "../recap/recap";
import { Button, Icon, Modal, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./saisie.css";
import Wait from "../wait/wait";

/**
 * DESCRIPTION : The "Saisie" Component display the page where user add manually, crypto stable and plateform. User can also change crypto quantity and delete crypto stable and plateform<br/><br/>
 * STATE :<br/>
 * status: [], : contains the result of the api get api/custom-account/ <br/>
 * limit : contains the limitation on addable plateform<br/>
 * intervalSetMargin : contains the time interval of change top margin<br/>
 * list_plateform : contains the list of the platform added by the user<br/>
 * list_crypto : contains the list of crypto added by the users<br/>
 * list_crypto_total : contains the list of all possible crypto<br/>
 * list_plateform_total : contains the list of all possible plateform<br/>
 * list_stable : contains the list of stable added by the users<br/>
 * list_stable_total : contains the list of all possible stable<br/>
 * modal_plateform : contains the information about plateform modal<br/>
 * modal_crypto : contains the information about crypto modal<br/>
 * modal_stable : contains the information about stable modal<br/>
 * old_value : contains the old value that will be modified<br/>
 * new_value : contains the modified value<br/>
 * error_plateform : contains the error message in plateform modal<br/>
 * error_crypto : contains the error message in crypto modal<br/>
 * error_stable : contains the error message in stable modal<br/>
 * confirm : contains the confirmation message when a value is updated<br/>
 * error : contains the error message<br/>
 * loading : is used to wait to all data set<br/><br/>
 * <br/><br/>
 * RETURN :<br/>
 * Apiconnexion page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Saisie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: [],
      limit: {},
      intervalSetMargin: "",
      list_plateform: [],
      list_crypto: [],
      list_crypto_total: [],
      list_plateform_total: [],
      list_stable: [],
      list_stable_total: [],
      exchange_list: [],
      exchange_crypto_list: [],
      exchange_stable_list: [],
      exchange_account: [],
      modal_plateform: {
        plateform_state: false,
        input: "",
      },
      modal_crypto: {
        crypto_state: false,
        input: "",
      },
      modal_stable: {
        stable_state: false,
        input: "",
      },
      old_value: 0,
      new_value: 0,
      error_plateform: "",
      error_crypto: "",
      error_stable: "",
      message_plateform: "",
      message_crypto: "",
      message_stable: "",
      confirm: "",
      error: "",
      loading: true,
      padding_table_right: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      padding_table_left: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      call_state_balances: false,
      call_state_custom_account: false,
      call_state_user_added_crypto: false,
    };
    this.changePlateformState = this.changePlateformState.bind(this);
    this.changeCryptoState = this.changeCryptoState.bind(this);
  }

  /**
   * function : changePlateformState is used to change  plateform modal state
   * input : _bool : represent the new state of plateform modal
   * output : none
   */
  changePlateformState = (_bool) => {
    this.setState({ modal_plateform: { plateform_state: _bool } });
  };

  /**
   * function : changeCryptoState is used to change crypto modal state
   * input : _bool : represent the new state of crypto modal
   * output : none
   */
  changeCryptoState = (_bool) => {
    this.setState({ modal_crypto: { crypto_state: _bool } });
    const listItem = document.querySelector("#crypto_list");
    var crypto = document
      .getElementById("selected_crypto_saisie")
      .value.toUpperCase();
    var datalist = document.createElement("datalist");
    datalist.id = "crypto_list";
    this.state.list_crypto_total.map((child) => {
      if (
        child.name.toUpperCase().startsWith(crypto, 0) ||
        child.symbol.toUpperCase().startsWith(crypto, 0)
      ) {
        var option = document.createElement("option");
        option.value = child.symbol.toUpperCase();
        option.textContent = child.name;
        datalist.appendChild(option);
      }
    });
    listItem.parentNode.replaceChild(datalist, listItem);
  };

  /**
   * function : changeStableState is used to change modal stable state
   * input : _bool : represent the new state of stable modal
   * output : none
   */
  changeStableState = (_bool) => {
    this.setState({ modal_stable: { stable_state: _bool } });
  };

  /**
   * function : getAllCoin is used to get the list of all possible coin
   * input : none
   * output : none
   */
  getAllCoin = () => {
    var crypto_present = [];
    const requestOptions = {
      method: "GET",
    };
    fetch(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&per_page=250",
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          data.map((crypto) => {
            crypto_present.push(crypto.name);
          });
          this.setState({
            list_crypto_total: data,
          });
          fetch("https://api.coingecko.com/api/v3/coins/list", requestOptions)
            .then((res) =>
              res.json().then((data) => {
                data.map((crypto) => {
                  if (crypto_present.indexOf(crypto.name) === -1) {
                    this.state.list_crypto_total.push(crypto);
                  }
                });
              })
            )
            .catch((err) => console.log(err));
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getRetriction is used to get the account limiation using API
   * input : none
   * output : none
   */
  getRetriction = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "user-subscription-limits", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ limit: data });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getValuesWithCryptoAndPlateform is used to get the amount of a crypto on a plateform
   * input : none
   * output : none
   */
  getValuesWithCryptoAndPlateform = (_id_plateform, _crypto) => {
    var _id_crypto = 0;
    this.state.status[_id_plateform].balances.map((balance, id) => {
      if (
        balance.user_added_crypto.cryptocurrency.name ===
        _crypto.cryptocurrency.name
      ) {
        _id_crypto = id;
      }
    });
    try {
      var result =
        this.state.status[_id_plateform].balances[_id_crypto].quantity;
      result = result.toString();
      result = result.replace(/[^\d.]/g, "");
      result = result.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      result = result.replace(/\.(?=.*\.)/g, "");
      var parts = result.split(".");
      if (parts.length === 2) {
        result = parts[0] + "." + parts[1].replaceAll(" ", "");
      }
      return result;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  /**
   * function : getCryptoValuesInExchange is used to take the all of input according to exchange and crypto
   * input :
   *  _exch : the exchange
   *  _cry : the crypto
   *  _id : the id in the list
   * output : none
   */
  getCryptoValuesInExchange = (_exch, _cry, _id) => {
    try {
      var result = this.state.exchange_account.balances[_exch][_cry].balance;
      result = result.toString();
      result = result.replace(/[^\d.]/g, "");
      result = result.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      result = result.replace(/\.(?=.*\.)/g, "");
      var parts = result.split(".");
      if (parts.length === 2) {
        result = parts[0] + "." + parts[1].replaceAll(" ", "");
      }
      return result;
    } catch (error) {
      return 0;
    }
  };

  /**
   * function : getStable is used to get the list of stable added by user
   * input : none
   * output : none
   */
  getStable = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/stablecoins/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ list_stable_total: data });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getAccountPlateform is used to get the list of all plateform with theirs balances
   * input : none
   * output : none
   */
  getAccountPlateform = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/custom-account/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ list_plateform: [] });
          this.setState({ status: data });
          data.map((plat) => {
            this.state.list_plateform.push(plat.name);
          });
          this.setState({ call_state_custom_account: true });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getUserAddedCrypto is used to get the list of crypto added by the user
   * input : none
   * output : none
   */
  getUserAddedCrypto = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/user-added-crypto/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ list_crypto: [] });
          this.setState({ list_stable: [] });
          data.map((crypto) => {
            try {
              this.state.padding_table_left.shift();
            } catch (error) {
              console.log(error);
            }

            if (crypto.cryptocurrency.stablecoin === true) {
              this.state.list_stable.push(crypto);
            } else {
              this.state.list_crypto.push(crypto);
            }
            this.setState({ call_state_user_added_crypto: true });
            setTimeout(() => {
              this.adjustPadding();
            }, 500);
          });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getExchangeBalance is used to get the list of crypto recover in exchanges
   * input : none
   * output : none
   */
  getExchangeBalance = () => {
    var exchange_list = [];
    var crypto_list = [];
    var stable_list = [];
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "crypto/accounts/exchanges/balances", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          for (var key in data.balances) {
            exchange_list.push(key);
            for (var cry in data.balances[key]) {
              if (data.balances[key][cry].stablecoin) {
                stable_list.push(cry);
              } else {
                crypto_list.push(cry);
              }
            }
          }

          crypto_list.map((crypto) => {
            this.state.padding_table_right.shift();
          });
          stable_list.map((stable) => {
            this.state.padding_table_right.shift();
          });

          this.setState({ exchange_list: exchange_list });
          this.setState({ exchange_crypto_list: crypto_list });
          this.setState({ exchange_stable_list: stable_list });
          this.setState({ exchange_account: data });
          this.setState({ call_state_balances: true });
          this.adjustPadding();
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : addCrypto is used to add a crypto to user account
   * input : none
   * output : none
   */
  addCrypto = () => {
    var toAdd = document.getElementById("selected_crypto_saisie").value;
    var result;
    {
      this.state.list_crypto_total.map((crypto) => {
        if (
          crypto.name.toLowerCase() === toAdd.toLowerCase() ||
          crypto.symbol.toLowerCase() === toAdd.toLowerCase()
        ) {
          result = {
            name: crypto.name,
            symbol: crypto.symbol,
            coingecko_id: crypto.id,
            stablecoin: false,
          };
        }
      });
    }
    if (result === undefined) {
      this.setState({ error_crypto: "Veuillez entrer une crypto valide" });
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        cryptocurrency: result,
      }),
    };
    fetch(Urls.urls_api + "api/user-added-crypto/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.id !== undefined) {
            this.getAccountPlateform();
            this.getUserAddedCrypto();
            this.setState({
              message_crypto: "Ajout réussi, veuillez patienter",
            });
            setTimeout(() => {
              this.setState({ modal_crypto: { crypto_state: false } });
              this.setState({ message_crypto: "" });
            }, 2000);
          } else if (
            data.cryptocurrency[0] ===
            "The CryptoCurrency with this coingecko_id already exists for this user."
          ) {
            this.setState({ error_crypto: "Cette crypto a déja été ajoutée" });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : addStable is used to add a stable to user account
   * input : none
   * output : none
   */
  addStable = () => {
    var toAdd = document.getElementById("selected_stable").value;
    var result;
    {
      this.state.list_stable_total.map((stable) => {
        if (stable.name === toAdd && stable.name !== "") {
          result = {
            name: stable.name,
            symbol: stable.symbol,
            coingecko_id: stable.coingecko_id,
            stablecoin: true,
          };
        }
      });
    }
    if (result === undefined) {
      this.setState({ error_stable: "Veuillez entrer un stable valide" });
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        cryptocurrency: result,
      }),
    };
    fetch(Urls.urls_api + "api/user-added-crypto/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.id !== undefined) {
            this.getAccountPlateform();
            this.getUserAddedCrypto();
            this.setState({
              message_stable: "Ajout réussi, veuillez patienter",
            });
            setTimeout(() => {
              this.setState({ modal_stable: { stable_state: false } });
              this.setState({ message_stable: "" });
            }, 2000);
          } else if (
            data.cryptocurrency[0] ===
            "The CryptoCurrency with this coingecko_id already exists for this user."
          ) {
            this.setState({
              error_stable: "Cette stablecoin a déja été ajoutée",
            });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : addPlateform is used to add a plateform to user account
   * input : none
   * output : none
   */
  addPlateform = () => {
    if (
      this.state.list_plateform.length >= this.state.limit.custom_accounts_limit
    ) {
      this.setState({
        error_plateform:
          "Ajout de plateforme impossible. Veuillez vérifier votre abonnement",
      });
      return;
    }
    var toAdd = document.getElementById("selected_plateform").value;
    if (this.state.list_plateform.includes(toAdd)) {
      this.setState({
        error_plateform: "Plateforme déja ajoutée",
      });
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        name: toAdd,
      }),
    };
    fetch(Urls.urls_api + "api/custom-account/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.getAccountPlateform();
          this.getUserAddedCrypto();
          this.setState({
            message_plateform: "Ajout réussi, veuillez patienter",
          });
          setTimeout(() => {
            this.setState({ modal_plateform: { plateform_state: false } });
            this.setState({ message_plateform: "" });
          }, 2000);
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : deletePlateform is used to delete a plateform from user account
   * input : _name : name of the concerned plateform
   * output : none
   */
  deletePlateform = (_name) => {
    var id = this.extractExchangeId(_name);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/custom-account/" + id + "/", requestOptions)
      .then((res) => res.json().then((data) => {}))
      .catch((err) => {
        this.getAccountPlateform();
        this.getUserAddedCrypto();
        this.setState({ confirm: "Plateforme supprimée, veuillez patienter" });
        setTimeout(() => {
          this.setState({ confirm: "" });
        }, 2000);
      });
  };

  /**
   * function : deleteCrypto is used to delete a crypto from user account
   * input : _cryptocurrency : name of the concerned crypto
   * output : none
   */
  deleteCrypto = (_cryptocurrency) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        cryptocurrency: _cryptocurrency,
      }),
    };
    fetch(
      Urls.urls_api + "api/user-added-crypto/" + _cryptocurrency.id + "/",
      requestOptions
    )
      .then((res) => res.json().then((data) => {}))
      .catch((err) => {
        this.getAccountPlateform();
        this.getUserAddedCrypto();
        this.setState({ confirm: "Crypto supprimée, veuillez patienter" });
        setTimeout(() => {
          this.setState({ confirm: "" });
        }, 2000);
      });
  };

  /**
   * function : extractExchangeId is used to extract a exchange id
   * input : _name : name of the exchange
   * output : result : an exchange (plateform) id
   */
  extractExchangeId = (_name) => {
    var result = 0;
    this.state.status.map((plateform) => {
      if (plateform.name === _name) {
        result = plateform.id;
      }
    });
    return result;
  };

  /**
   * function : extractUserAddedCryptoId is used to extract a id of UserAddedCryptoId
   * input : _plateform : name of the concerned exchange
   *         _crypto : name of the concerned crypto
   * output : result : an UserAddedCryptoId id
   */
  extractUserAddedCryptoId = (_plateform, _crypto) => {
    var result;
    this.state.status.map((plat) => {
      plat.balances.map((bal) => {
        if (
          bal.user_added_crypto.cryptocurrency.coingecko_id ===
            _crypto.cryptocurrency.coingecko_id &&
          plat.name === _plateform
        ) {
          result = bal.id;
        }
      });
    });
    return result;
  };

  /**
   * function : changeMarginDiv is used to adjust the top margin of the main div.
   * input : none
   * output : none
   */
  changeMarginDiv = () => {
    if (window.innerWidth > 600) {
      var headerHeight = document.getElementById("header").clientHeight;
      var recapHeight = document.getElementById("recap").clientHeight;
      document
        .getElementById("saisie")
        .style.setProperty("top", headerHeight + recapHeight - 20 + "px");
    } else {
      var headerHeight =
        document.getElementById("header_smartphone").clientHeight;
      var recapHeight =
        document.getElementById("recap_smartphone").clientHeight;
      document
        .getElementById("saisie_tel")
        .style.setProperty("top", headerHeight + recapHeight + 10 + "px");
    }
  };

  /**
   * function : handleOnFocus is used to set the old value (see comment on state)
   * input : _value : old value to set
   * output : none
   */
  handleOnFocus = (_value) => {
    this.setState({ old_value: _value });
  };

  /**
   * function : handleOffFocus is used to set a new crypto quantity
   * input : none
   * output : none
   */
  handleOffFocus = (_crypto, _plateform, event) => {
    var quantity = event.target.value.replace(/\s/g, "");
    if (this.state.old_value.toString() === event.target.value.toString()) {
      return;
    }
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        user_added_crypto: {
          cryptocurrency: {
            name: _crypto.cryptocurrency.name,
            symbol: _crypto.cryptocurrency.symbol,
            coingecko_id: _crypto.cryptocurrency.coingecko_id,
            stablecoin: _crypto.cryptocurrency.stablecoin,
          },
        },
        user_added_crypto_id: _crypto.id,
        quantity: quantity,
        account: this.extractExchangeId(_plateform),
      }),
    };
    fetch(
      Urls.urls_api +
        "api/custom-balance/" +
        this.extractUserAddedCryptoId(_plateform, _crypto) +
        "/",
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          this.setState({
            confirm:
              "Changement effectué, Veuillez recharger pour actualiser le bandeau.",
          });
          this.setState({ error: "" });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ confirm: "" });
        this.setState({ error: "Changement non effectué, veuillez réessayer" });
      });
  };

  /**
   * function : adjustPadding is used to set padding tr of the tables
   * input : none
   * output : none
   */
  adjustPadding = () => {
    var custom_lenght =
      this.state.list_crypto.length + this.state.list_stable.length;
    if (this.state.list_plateform.length !== 0) {
      custom_lenght += 1;
    }
    var exchange_lenght =
      this.state.exchange_crypto_list.length +
      this.state.exchange_stable_list.length;
    if (this.state.exchange_list.length !== 0) {
      exchange_lenght += 1;
    }
    var tab_platform = [];
    var tab_exchange = [];
    var counter = 0;
    if (custom_lenght >= 16 || exchange_lenght >= 16) {
      if (custom_lenght > exchange_lenght) {
        counter = custom_lenght - exchange_lenght;
        for (let i = 0; i < counter; i++) {
          tab_exchange.push("");
        }
      } else {
        counter = exchange_lenght - custom_lenght;
        for (let i = 0; i < counter; i++) {
          tab_platform.push("");
        }
      }
    } else {
      counter = 16 - exchange_lenght;
      for (let i = 0; i < counter; i++) {
        tab_exchange.push("");
      }
      counter = 16 - custom_lenght;
      for (let i = 0; i < counter; i++) {
        tab_platform.push("");
      }
    }
    this.setState({ padding_table_left: tab_platform });
    this.setState({ padding_table_right: tab_exchange });
  };

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to get all plateform, stable and crypto possible; get all plateform stable and crypto added;
   * extract all value needed
   */
  componentDidMount() {
    if (
      window.sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server;
    } else {
      this.getExchangeBalance();
      this.getAccountPlateform();
      this.getAllCoin();
      this.getStable();
      this.getUserAddedCrypto();
      this.getRetriction();
      setTimeout(() => {
        this.setState({ loading: false });
      }, 15000);
      this.intervalId = setInterval(() => {
        this.changeMarginDiv();
      }, 1000);
    }
  }

  numberFormat = () => {
    var result = event.target.value;
    result = result.replace(/[^\d.]/g, "");
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    result = result.replace(/\.(?=.*\.)/g, "");
    var parts = result.split(".");
    if (parts.length === 2) {
      result = parts[0] + "." + parts[1].replaceAll(" ", "");
    }
    event.target.value = result;
  };

  /**
   * this function will be execute as soon as the component will be removed.
   * it is used to get off the interval
   */
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidCatch() {
    clearInterval(this.intervalId);
  }

  componentDidUpdate() {
    if (this.state.loading) {
      if (
        this.state.call_state_balances &&
        this.state.call_state_custom_account &&
        this.state.call_state_user_added_crypto
      ) {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div id="wait_container">
          <Recap />
          <Wait />
        </div>
      );
    }
    if (window.innerWidth > 600) {
      return (
        <div className="fade-in" id="saisie">
          <Recap />
          <h2 id="green_label">{this.state.confirm}</h2>
          <h2 id="text_error">{this.state.error}</h2>
          <Link id="link_to_api_connect" to="/apiconnexion">
            <Button id="button_to_api_connect" animated="vertical">
              <Button.Content visible>
                <h4>API : Connecte tes plateformes ici</h4>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="angle right" />
              </Button.Content>
            </Button>
          </Link>
          <br />
          <div id="div_button_saisie">
            <Modal
              id="modal_modal_plateform"
              onClose={() => this.changePlateformState(false)}
              onOpen={() => this.changePlateformState(true)}
              open={this.state.modal_plateform.plateform_state}
              trigger={
                <Button id="button_saisie" animated="vertical">
                  <Button.Content visible>
                    <h3>Ajouter une plateforme</h3>
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="modal_plateform">
                <h2>Choisissez votre plateforme à ajouter</h2>
                <h3 id="text_message">{this.state.message_plateform}</h3>
                <h3 id="text_error">{this.state.error_plateform}</h3>
                <label id="label">Plateforme</label>
                <br />
                <Input
                  id="selected_plateform"
                  onChange={this.handleChangePlateform}
                  placeholder="Choisir la plateforme"
                />
                <br />
                <Button
                  onClick={() => this.addPlateform()}
                  id="main_button"
                  animated="fade"
                >
                  <Button.Content visible>
                    <h4>Ajouter</h4>
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="add circle" />
                  </Button.Content>
                </Button>
              </div>
            </Modal>
            <Modal
              id="modal_modal_crypto"
              onClose={() => this.changeCryptoState(false)}
              onOpen={() => this.changeCryptoState(true)}
              open={this.state.modal_crypto.crypto_state}
              trigger={
                <Button id="button_saisie" animated>
                  <Button.Content visible>
                    <h3>Ajouter une crypto</h3>
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="modal_crypto">
                <h2>Choisissez votre crypto à ajouter</h2>
                <h3 id="text_message">{this.state.message_crypto}</h3>
                <h3 id="text_error">{this.state.error_crypto}</h3>
                <label id="label">Crypto</label>
                <br />
                <Input
                  id="selected_crypto_saisie"
                  list="crypto_list"
                  onChange={this.changeCryptoState}
                  placeholder="Choisir la crypto"
                  pattern=""
                />
                <datalist id="crypto_list">
                  {this.state.list_crypto_total.map((crypto) => (
                    <option value={crypto.name}>
                      {crypto.symbol.toUpperCase()}
                    </option>
                  ))}
                </datalist>

                <br />
                <Button
                  onClick={() => this.addCrypto()}
                  id="main_button"
                  animated="fade"
                >
                  <Button.Content visible>
                    <h4>Ajouter</h4>
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="add circle" />
                  </Button.Content>
                </Button>
              </div>
            </Modal>

            <Modal
              id="modal_modal_stable"
              onClose={() => this.changeStableState(false)}
              onOpen={() => this.changeStableState(true)}
              open={this.state.modal_stable.stable_state}
              trigger={
                <Button id="button_saisie" animated>
                  <Button.Content visible>
                    <h3>Ajouter une stablecoin</h3>
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="modal_stable">
                <h2>Choisissez votre stable à ajouter</h2>
                <h3 id="text_message">{this.state.message_stable}</h3>
                <h3 id="text_error">{this.state.error_stable}</h3>
                <label id="label">Stable</label>
                <br />
                <Input
                  list="plateforme"
                  onChange={this.changeStableState}
                  placeholder="Choisir le stable"
                  id="selected_stable"
                />
                <datalist id="plateforme">
                  {this.state.list_stable_total.map((stable) => (
                    <option value={stable.name}>{stable.name}</option>
                  ))}
                </datalist>
                <br />
                <Button
                  onClick={() => this.addStable()}
                  id="main_button"
                  animated="fade"
                >
                  <Button.Content visible>
                    <h4>Ajouter</h4>
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="add circle" />
                  </Button.Content>
                </Button>
              </div>
            </Modal>
          </div>
          <br />
          <div id="align_table">
            <div id="table_api">
              <h2>Plateformes et cryptos ajoutées manuellement</h2>
              <div id="table_api_1">
                <table id="table_saisie">
                  <thead>
                    {(() => {
                      if (this.state.list_plateform.length !== 0) {
                        return <th></th>;
                      }
                    })()}
                    {this.state.list_plateform.map((plateform) => (
                      <th>
                        <div id="header_table_plateform">
                          <Button
                            id="button_delete_plateform"
                            onClick={() => this.deletePlateform(plateform)}
                            size="mini"
                            icon
                          >
                            <h5>X</h5>
                          </Button>
                          {plateform}
                        </div>
                      </th>
                    ))}
                  </thead>
                  <tbody id="tbody_stable">
                    {this.state.list_stable.map((stable, id_stable) => (
                      <tr>
                        <td>
                          <div id="header_table_plateform">
                            <Button
                              id="button_delete_plateform"
                              onClick={() => this.deleteCrypto(stable)}
                              size="mini"
                              icon
                            >
                              <h5>X</h5>
                            </Button>
                            {stable.cryptocurrency.symbol.toUpperCase()}
                          </div>
                        </td>
                        {this.state.list_plateform.map((plateform, id_tab) => (
                          <td>
                            <input
                              min={0}
                              className="input_table"
                              defaultValue={this.getValuesWithCryptoAndPlateform(
                                id_tab,
                                stable
                              )}
                              onFocus={() =>
                                this.handleOnFocus(
                                  this.getValuesWithCryptoAndPlateform(
                                    id_tab,
                                    stable
                                  )
                                )
                              }
                              onBlur={() =>
                                this.handleOffFocus(stable, plateform, event)
                              }
                              type="text"
                              onChange={() => this.numberFormat()}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <tbody id="tbody_crypto">
                    {this.state.list_crypto.map((crypto, id_crypto) => (
                      <tr>
                        <td>
                          <div id="header_table_plateform">
                            <Button
                              id="button_delete_plateform"
                              onClick={() => this.deleteCrypto(crypto)}
                              size="mini"
                              icon
                            >
                              <h5>X</h5>
                            </Button>
                            {crypto.cryptocurrency.symbol.toUpperCase()}
                          </div>
                        </td>
                        {this.state.list_plateform.map((plateform, id_tab) => (
                          <td>
                            <input
                              min={0}
                              className="input_table"
                              defaultValue={this.getValuesWithCryptoAndPlateform(
                                id_tab,
                                crypto
                              )}
                              onFocus={() =>
                                this.handleOnFocus(
                                  this.getValuesWithCryptoAndPlateform(
                                    id_tab,
                                    crypto
                                  )
                                )
                              }
                              onBlur={() =>
                                this.handleOffFocus(crypto, plateform, event)
                              }
                              type="text"
                              onChange={() => this.numberFormat()}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                    {this.state.padding_table_left.map((pad) => (
                      <tr>
                        <td></td>
                        {this.state.list_plateform.map((plat) => (
                          <td></td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <div id="table_api">
              <h2>Plateformes connectées par API</h2>
              <div id="table_api_2">
                <table id="table_saisie">
                  {(() => {
                    if (this.state.exchange_list.length !== 0) {
                      return (
                        <thead id="thead_exchange">
                          <th></th>
                          {this.state.exchange_list.map((exch) => (
                            <th>{exch}</th>
                          ))}
                        </thead>
                      );
                    }
                  })()}
                  <tbody id="tbody_api_stable">
                    {this.state.exchange_stable_list.map((stable) => (
                      <tr>
                        <td>{stable}</td>
                        {this.state.exchange_list.map((exch, id) => (
                          <td>
                            <input
                              className="input_table"
                              value={this.getCryptoValuesInExchange(
                                exch,
                                stable,
                                id
                              )}
                              type="text"
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <tbody>
                    {this.state.exchange_crypto_list.map((cry) => (
                      <tr>
                        <td>{cry}</td>
                        {this.state.exchange_list.map((exch, id) => (
                          <td>
                            <input
                              className="input_table"
                              value={this.getCryptoValuesInExchange(
                                exch,
                                cry,
                                id
                              )}
                              type="text"
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                    {this.state.padding_table_right.map((pad) => (
                      <tr>
                        <td></td>
                        {this.state.exchange_list.map((plat) => (
                          <td></td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="saisie_tel">
          <Recap />
          <h3 id="green_label">{this.state.confirm}</h3>
          <h3 id="text_error">{this.state.error}</h3>
          <Link id="link_to_api_connect" to="/apiconnexion">
            <Button id="button_to_api_connect" animated="vertical">
              <Button.Content id="content_button" visible>
                <h6>API : Connecte tes plateformes ici !</h6>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="angle right" />
              </Button.Content>
            </Button>
          </Link>
          <br />
          <div id="div_button_saisie_tel">
            <div style={{ width: "100%", textAlignLast: "center" }}>
              <Modal
                id="modal_modal_plateform"
                onClose={() => this.changePlateformState(false)}
                onOpen={() => this.changePlateformState(true)}
                open={this.state.modal_plateform.plateform_state}
                trigger={
                  <Button id="button_saisie_tel" animated="vertical">
                    <Button.Content id="content_button" visible>
                      <h6>Ajouter une plateforme</h6>
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="angle right" />
                    </Button.Content>
                  </Button>
                }
              >
                <div id="modal_plateform">
                  <h2>Choisissez votre plateforme à ajouter</h2>
                  <h3 id="text_message">{this.state.message_plateform}</h3>
                  <h3 id="text_error">{this.state.error_plateform}</h3>
                  <label id="label">Plateforme</label>
                  <br />
                  <Input
                    id="selected_plateform"
                    onChange={this.handleChangePlateform}
                    placeholder="Choisir la plateforme"
                  />
                  <br />
                  <Button
                    onClick={() => this.addPlateform()}
                    id="main_button"
                    animated="fade"
                  >
                    <Button.Content visible>
                      <h4>Ajouter</h4>
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="add circle" />
                    </Button.Content>
                  </Button>
                </div>
              </Modal>
            </div>
            <br />
            <div style={{ width: "100%", textAlignLast: "center" }}>
              <Modal
                id="modal_modal_crypto"
                onClose={() => this.changeCryptoState(false)}
                onOpen={() => this.changeCryptoState(true)}
                open={this.state.modal_crypto.crypto_state}
                trigger={
                  <Button id="button_saisie_tel" animated>
                    <Button.Content id="content_button" visible>
                      <h6>Ajouter une crypto</h6>
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="angle right" />
                    </Button.Content>
                  </Button>
                }
              >
                <div id="modal_crypto">
                  <h2>Choisissez votre crypto à ajouter</h2>
                  <h3 id="text_message">{this.state.message_crypto}</h3>
                  <h3 id="text_error">{this.state.error_crypto}</h3>
                  <label id="label">Crypto</label>
                  <br />
                  <Input
                    id="selected_crypto_saisie"
                    list="crypto_list"
                    onChange={this.changeCryptoState}
                    placeholder="Choisir la crypto"
                  />
                  <datalist id="crypto_list">
                    {this.state.list_crypto_total.map((crypto) => (
                      <option value={crypto.name}>{crypto.name}</option>
                    ))}
                  </datalist>
                  <br />
                  <Button
                    onClick={() => this.addCrypto()}
                    id="main_button"
                    animated="fade"
                  >
                    <Button.Content visible>
                      <h4>Ajouter</h4>
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="add circle" />
                    </Button.Content>
                  </Button>
                </div>
              </Modal>

              <Modal
                id="modal_modal_stable"
                onClose={() => this.changeStableState(false)}
                onOpen={() => this.changeStableState(true)}
                open={this.state.modal_stable.stable_state}
                trigger={
                  <Button id="button_saisie_tel" animated>
                    <Button.Content id="content_button" visible>
                      <h6>Ajouter une stablecoin</h6>
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="angle right" />
                    </Button.Content>
                  </Button>
                }
              >
                <div id="modal_stable">
                  <h2>Choisissez votre stable à ajouter</h2>
                  <h3 id="text_message">{this.state.message_stable}</h3>
                  <h3 id="text_error">{this.state.error_stable}</h3>
                  <label id="label">Stable</label>
                  <br />
                  <Input
                    list="stable"
                    onChange={this.changeStableState}
                    placeholder="Choisir le stable"
                    id="selected_stable"
                  />
                  <datalist id="stable">
                    {this.state.list_stable_total.map((stable) => (
                      <option value={stable.name}>{stable.name}</option>
                    ))}
                  </datalist>
                  <br />
                  <Button
                    onClick={() => this.addStable()}
                    id="main_button"
                    animated="fade"
                  >
                    <Button.Content visible>
                      <h4>Ajouter</h4>
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="add circle" />
                    </Button.Content>
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
          <br />
          <br />
          <div id="table_api">
            <h3>Plateformes et cryptos ajoutées manuellement</h3>
            <div id="table_api_1">
              <table id="table_saisie">
                <thead>
                  {(() => {
                    if (this.state.list_plateform.length !== 0) {
                      return <th></th>;
                    }
                  })()}
                  {this.state.list_plateform.map((plateform) => (
                    <th>
                      <div id="header_table_plateform">
                        <Button
                          id="button_delete_plateform"
                          onClick={() => this.deletePlateform(plateform)}
                          size="mini"
                          icon
                        >
                          <h5>X</h5>
                        </Button>
                        {plateform}
                      </div>
                    </th>
                  ))}
                </thead>
                <tbody id="tbody_stable">
                  {this.state.list_stable.map((stable, id_stable) => (
                    <tr>
                      <td>
                        <div id="header_table_plateform">
                          <Button
                            id="button_delete_plateform"
                            onClick={() => this.deleteCrypto(stable)}
                            size="mini"
                            icon
                          >
                            <h5>X</h5>
                          </Button>
                          {stable.cryptocurrency.symbol.toUpperCase()}
                        </div>
                      </td>
                      {this.state.list_plateform.map((plateform, id_tab) => (
                        <td>
                          <input
                            min={0}
                            className="input_table"
                            defaultValue={this.getValuesWithCryptoAndPlateform(
                              id_tab,
                              stable
                            )}
                            onFocus={() =>
                              this.handleOnFocus(
                                this.getValuesWithCryptoAndPlateform(
                                  id_tab,
                                  stable
                                )
                              )
                            }
                            onBlur={() =>
                              this.handleOffFocus(stable, plateform, event)
                            }
                            type="text"
                            onChange={() => this.numberFormat()}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tbody id="tbody_crypto">
                  {this.state.list_crypto.map((crypto, id_crypto) => (
                    <tr>
                      <td>
                        <div id="header_table_plateform">
                          <Button
                            id="button_delete_plateform"
                            onClick={() => this.deleteCrypto(crypto)}
                            size="mini"
                            icon
                          >
                            <h5>X</h5>
                          </Button>
                          {crypto.cryptocurrency.symbol.toUpperCase()}
                        </div>
                      </td>
                      {this.state.list_plateform.map((plateform, id_tab) => (
                        <td>
                          <input
                            min={0}
                            className="input_table"
                            defaultValue={this.getValuesWithCryptoAndPlateform(
                              id_tab,
                              crypto
                            )}
                            onFocus={() =>
                              this.handleOnFocus(
                                this.getValuesWithCryptoAndPlateform(
                                  id_tab,
                                  crypto
                                )
                              )
                            }
                            onBlur={() =>
                              this.handleOffFocus(crypto, plateform, event)
                            }
                            type="text"
                            onChange={() => this.numberFormat()}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                  {this.state.padding_table_left.map((pad) => (
                    <tr>
                      <td></td>
                      {this.state.list_plateform.map((plat) => (
                        <td></td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <div id="table_api">
            <h3>Plateformes connectées par API</h3>
            <div id="table_api_2">
              <table id="table_saisie">
                <thead id="thead_exchange">
                  {(() => {
                    if (this.state.exchange_list.length !== 0) {
                      return <th></th>;
                    }
                  })()}
                  {this.state.exchange_list.map((exch) => (
                    <th>{exch}</th>
                  ))}
                </thead>
                <tbody id="tbody_api_stable">
                  {this.state.exchange_stable_list.map((stable) => (
                    <tr>
                      <td>{stable}</td>
                      {this.state.exchange_list.map((exch, id) => (
                        <td>
                          <input
                            className="input_table"
                            value={this.getCryptoValuesInExchange(
                              exch,
                              stable,
                              id
                            )}
                            type="text"
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tbody>
                  {this.state.exchange_crypto_list.map((cry) => (
                    <tr>
                      <td>{cry}</td>
                      {this.state.exchange_list.map((exch, id) => (
                        <td>
                          <input
                            className="input_table"
                            value={this.getCryptoValuesInExchange(
                              exch,
                              cry,
                              id
                            )}
                            type="text"
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                  {this.state.padding_table_right.map((pad) => (
                    <tr>
                      <td></td>
                      {this.state.exchange_list.map((plat) => (
                        <td></td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      );
    }
  }
}
