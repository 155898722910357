import React, { Component } from "react";
import { Button, Checkbox, Form } from "semantic-ui-react";
import "./inscription.css";
import { Link } from "react-router-dom";
import Urls from "../urls/urls";
import { GoogleLogin } from "react-google-login";
import GoogleButton from "react-google-button";

/**
 * DESCRIPTION : The "Inscription" Component display the signup. The user can
 * choose betweend signup with mail adress or google account.
 * <br/><br/>
 * STATE :<br/>
 * mail : contains the mail entered by the user<br/>
 * password : contains the password entered by the user<br/>
 * error : contains the error which will be displayed<br/>
 * issamepassword : contains the error is password and confirm_password are not the same<br/>
 * check : contains the boolean which represent checkbox<br/>
 * confirm_password : contains the verification of the confirmation field<br/><br/>
 * RETURN :<br/>
 * login page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Inscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      mail: "",
      confirm_password: "",
      issamepassword: "",
      error: "",
      confirm: "",
      check: false,
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeConfirmPassword =
      this.handleChangeConfirmPassword.bind(this);
    this.handleChangeMail = this.handleChangeMail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangePrename = this.handleChangePrename.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
  }

  /**
   * function : handleChangePassword is used to change password state
   * input : the field which contain the password
   * output : none
   */
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  /**
   * function : handleChangeCheckbox is used to change check state
   * input : the event button
   * output : none
   */
  handleChangeCheckbox(event) {
    var checked = !this.state.check;
    this.setState({ check: checked });
  }

  /**
   * function : handleChangeConfirmPassword is used to change password confirmation state
   * input : the field which contain the password confirmation
   * output : none
   */
  handleChangeConfirmPassword(event) {
    this.setState({ confirm_password: event.target.value });
    setTimeout(() => {
      if (this.state.confirm_password === this.state.password) {
        this.setState({ issamepassword: "" });
      } else {
        this.setState({
          issamepassword: "Les mots de passe ne sont pas identiques",
        });
      }
    }, 100);
  }

  /**
   * function : handleChangeName is used to change name state
   * input : the field which contain the name
   * output : none
   */
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }
  /**
   * function : handleChangePrename is used to change prename state
   * input : the field which contain the prename
   * output : none
   */
  handleChangePrename(event) {
    this.setState({ prename: event.target.value });
  }
  /**
   * function : handleChangeMail is used to change mail state
   * input : the field which contain the mail
   * output : none
   */
  handleChangeMail(event) {
    this.setState({ mail: event.target.value });
  }

  /**
   * function : successGoogle is used to connect user with Google auth
   * input : response of Google request
   * output : none
   */
  successGoogle = (response) => {
    this.setState({ confirm: "Inscription en cours, veuillez patienter" });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        access_token: response.accessToken,
      }),
    };
    fetch(Urls.urls_api + "dj-rest-auth/google/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ confirm: "" });
          if (data.key !== undefined) {
            sessionStorage.setItem("token", data.key);
            sessionStorage.setItem("uid", data.user);
            sessionStorage.setItem("photo", response.profileObj.imageUrl);
            window.location.href = Urls.urls_server + "load";
          } else if (
            data.non_field_errors !== undefined &&
            data.non_field_errors[0] ===
              "User is already registered with this e-mail address."
          ) {
            this.setState({
              error: "Cette adresse mail est déja enregistrée.",
            });
          }
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * function : failureGoogle is used if Google auth fail
   * input : response of Google request
   * output : none
   */
  failureGoogle = (response) => {
    this.setState({ error: "Probleme avec la connexion Google." });
  };

  /**
   * function : handleSubmit will do a call api to the back-end server
   * to insert new user in the database. handleSubmit will verify if the CGU checkbox if ok
   * .After that, a mail will be sent with a signup confirmation
   * input : the button onclick event
   * output : none
   */
  handleSubmit(event) {
    if (this.state.check === false) {
      // Check if the checkbox is not checked
      this.setState({
        error: "Veuillez accepter les conditions générales d'utilisation.",
      });
    } else {
      // Checkbox is checked, proceed with form submission

      // Prepare the request options for the API call
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.state.mail,
          password1: this.state.password,
          password2: this.state.confirm_password,
        }),
      };

      // Set loading message while the registration is in progress
      this.setState({ confirm: "Inscription en cours, veuillez patienter" });

      // Make the API call to register the user
      fetch(Urls.urls_api + "dj-rest-auth/registration/", requestOptions)
        .then((res) =>
          res.json().then((data) => {
            // Reset the loading message
            this.setState({ confirm: "" });

            // Handle the response data from the API

            // Check for errors related to email field
            if (data.email !== undefined) {
              if (data.email[0] === "This field may not be blank.") {
                this.setState({ error: "Veuillez remplir le formulaire." });
              } else if (
                data.email[0] ===
                "A user is already registered with this e-mail address."
              ) {
                this.setState({
                  error:
                    "Un utilisateur est déjà enregistré avec cette adresse e-mail.",
                });
              } else if (data.email[0] === "Enter a valid email address.") {
                this.setState({ error: "Entrer une adresse email valide." });
              }
            }

            // Check for errors related to password1 field
            else if (data.password1 !== undefined) {
              if (data.password1[0] === "This field may not be blank.") {
                this.setState({ error: "Veuillez remplir le formulaire." });
              } else if (
                data.password1[0] ===
                "This password is too short. It must contain at least 8 characters."
              ) {
                this.setState({
                  error:
                    "Ce mot de passe est trop court. Il doit contenir au moins 8 caractères",
                });
              } else if (
                data.password1[0] ===
                "The password is too similar to the email address."
              ) {
                this.setState({
                  error: "Le mot de passe est trop similaire à l'adresse mail",
                });
              } else if (data.password1[0] === "This password is too common.") {
                this.setState({ error: "Ce mot de passe est trop courant." });
              }
            }

            // Check for errors related to password2 field
            else if (data.password2 !== undefined) {
              if (data.password2[0] === "This field may not be blank.") {
                this.setState({ error: "Veuillez remplir le formulaire." });
              }
            }

            // Check for successful registration
            else if (data.detail === "Verification e-mail sent.") {
              // Redirect the user to the mail alert page
              window.location.href = Urls.urls_server + "mailalert";
            }

            // Other unknown errors
            else {
              this.setState({ error: "Veuillez réessayer." });
            }
          })
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    return (
      <div id="inscription">
        <h1 id="titre">Inscription</h1>
        <h3 id="green_label">{this.state.confirm}</h3>
        <h3 id="text_error">{this.state.error}</h3>
        <Form id="form">
          <Form.Field id="field">
            <label id="label">Email</label>
            <input
              type="email"
              placeholder="Email"
              value={this.state.mail}
              onChange={this.handleChangeMail}
            />
          </Form.Field>
          <Form.Field id="field">
            <label id="label">Mot de passe</label>
            <input
              type="password"
              placeholder="Mot de passe"
              value={this.state.password}
              onChange={this.handleChangePassword}
            />
          </Form.Field>
          <Form.Field id="field">
            <label id="label">Confirmer le mot de passe</label>
            <input
              type="password"
              placeholder="Confirmer mot de passe"
              value={this.state.confirm_password}
              onChange={this.handleChangeConfirmPassword}
            />
          </Form.Field>
          <Form.Field id="field">
            <h4 id="text_error">{this.state.issamepassword}</h4>
            <label id="label">
              J'accepte les{" "}
              <a href={Urls.urls_server + "mentions"}>
                conditions générales d'utilisation
              </a>
            </label>
            <Checkbox onChange={this.handleChangeCheckbox} label="" />
          </Form.Field>
          <Button id="main_button" type="submit" onClick={this.handleSubmit}>
            Inscription
          </Button>
        </Form>
        <div id="separator_or">
          <hr />
          <br />
          <h4>Ou</h4>
          <br />
          <hr />
        </div>
        <h3 id="text">Inscrivez vous avec votre compte Google</h3>
        <GoogleLogin
          className="google_button"
          clientId="121644861348-cko3qc5nbids2d53q3qj2sh3rc0rkoa9.apps.googleusercontent.com"
          buttonText="Inscription avec Google"
          onSuccess={this.successGoogle}
          onFailure={this.failureGoogle}
          cookiePolicy={"single_host_origin"}
        />
        <div id="separator_or">
          <hr />
          <br />
          <h4>Ou</h4>
          <br />
          <hr />
        </div>
        <Link id="green_label" to="/connexion">
          <h3 id="text">Connectez-vous</h3>
        </Link>
      </div>
    );
  }
}
