import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import Urls from "../urls/urls";
import "./changeinfo.css";
/**
 * Description : The class "Changeinfo" is the page where a user will be able to change his email<br />
 * STATE :<br/>
 * email : contains the email entered by the user<br/>
 * email : contains the confirmation message when the user will update his mail<br/><br/>
 * RETURN :<br/>
 * The change info div
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Changeinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to verify if the user is connected
   */
  componentDidMount() {
    if (
      window.sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server;
    }
    if (window.sessionStorage.getItem("photo") !== null) {
      window.location.href = Urls.urls_server + "dashboard";
    }
  }

  /**
   * function : handleChangeEmail is used to change username state
   * input : the field which contain the username
   * output : none
   */
  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  /**
   * function : handleSubmitEmail is used to change the user's email
   * input : none
   * output : none
   */
  handleSubmitEmail = () => {
    var uid = window.sessionStorage.getItem("uid");
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: this.state.email,
      }),
    };
    fetch(Urls.urls_api + "api/user/" + uid + "/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ message: "Votre email a bien été changé" });
        })
      )
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div id="changeinfo">
        <h1>
          Bienvenue sur la page de modification des informations de compte
        </h1>
        <h2>Ici, vous pouvez modifier votre email</h2>
        <h3 id="green_label">{this.state.message}</h3>
        <Form id="form">
          <Form.Field id="field">
            <label id="label">Email</label>
            <input
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleChangeEmail}
            />
          </Form.Field>
          <Button
            id="main_button"
            type="submit"
            onClick={this.handleSubmitEmail}
          >
            Changer d'email
          </Button>
        </Form>
      </div>
    );
  }
}
