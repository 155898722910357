import React, { Component } from "react";
import { Button, Icon, Confirm } from "semantic-ui-react";
import Urls from "../urls/urls";
import { Link } from "react-router-dom";
import "./settings.css";

/**
 * DESCRIPTION : The "Settings" Component display all useful user's information.
 * in the component, the user will be able to change his 2fa mode, sign out, change his password and his mail.
 * <br/><br/>
 * STATE :<br/>
 * email : contains the user's email or a default message<br/>
 * two_factor_message : contains the message about 2fa ("Activé" if the 2fa is on, "Désactivé" if the 2fa if off)<br/>
 * confirm_state_password : is used to know the state of the confirmation to change password windows<br/>
 * confirm_state_2fa : is used to know the state of the confirmation to change 2fa windows<br/>
 * confirm_password : contains the verification of the confirmation field<br/><br/>
 * RETURN :<br/>
 * Settings page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      two_factor_message: "",
      button_2fa: "",
      alert: "",
      confirm_state_password: false,
      confirm_state_2fa: false,
      user_subscription: "",
    };
  }

  /**
   * function : openChangePassword is used to open confirmation password windows
   * input : none
   * output : none
   */
  openChangePassword = () => this.setState({ confirm_state_password: true });

  /**
   * function : openChangePassword is used to clos confirmation password windows
   * input : none
   * output : none
   */
  closeChangePassword = () => this.setState({ confirm_state_password: false });

  /**
   * function : openChangePassword is used to open confirmation 2fa windows
   * input : none
   * output : none
   */
  openChange2fa = () => this.setState({ confirm_state_2fa: true });

  /**
   * function : openChangePassword is used to close confirmation 2fa windows
   * input : none
   * output : none
   */
  closeChange2fa = () => this.setState({ confirm_state_2fa: false });

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to verify if the user is connected and get the user's informations
   */
  componentDidMount() {
    if (
      window.sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server;
    } else {
      this.getInformation();
      this.getUserSubscription();
    }
  }

  /**
   * function : handleSetNewPassword is used to send a change password email
   * input : none
   * output : none
   */
  handleSetNewPassword = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: localStorage.getItem("email"),
      }),
    };
    fetch(Urls.urls_api + "dj-rest-auth/password/reset/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.detail === "Password reset e-mail has been sent.") {
            window.location.href = Urls.urls_server + "mailalert";
          } else {
            this.setState({
              alert:
                "Nous ne pouvons pas envoyer la requete actuellement. Veuillez réessayer ulterieurement",
            });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : handleDeconnexion is used to sign out the user
   * input : none
   * output : none
   */
  handleDeconnexion = () => {
    var token = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: token,
      }),
    };
    fetch(Urls.urls_api + "dj-rest-auth/logout/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          window.sessionStorage.removeItem("token");
          window.sessionStorage.removeItem("photo");
          window.sessionStorage.removeItem("email");
          window.sessionStorage.removeItem("uid");
          window.location.href = Urls.urls_server;
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : toTarif is used to redirect the used to the tarif page
   * input : none
   * output : none
   */
  toTarif = () => {
    window.location.href = Urls.urls_server + "tarif";
  };

  /**
   * function : toTarif is used to change the 2fa state
   * input : none
   * output : none
   */
  change2fa = () => {
    var _bool;
    if (this.state.button_2fa === "Activer 2FA") {
      _bool = "false";
    } else {
      _bool = "true";
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "send-email-toggle-2fa/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.closeChange2fa();
          window.location.href =
            Urls.urls_server + "change2fa?2fa_state=" + _bool;
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getInformation is used to get all useful user's information such as
   * the subscription, the email of the 2fa state
   * input : none
   * output : none
   */
  getInformation = () => {
    var uid = window.sessionStorage.getItem("uid");
    if (uid === "undefined") {
      this.setState({ email: window.sessionStorage.getItem("email") });
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/user/" + uid + "/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ username: data.username });
          this.setState({ first_name: data.first_name });
          this.setState({ last_name: data.last_name });
          this.setState({ email: data.email });
          if (data.two_factor_auth_email_enabled === true) {
            this.setState({ two_factor_message: "Activé" });
            this.setState({ button_2fa: "Desactiver 2FA" });
            document.getElementById("message_2fa").style.color = "green";
          } else {
            this.setState({ two_factor_message: "Desactivé" });
            this.setState({ button_2fa: "Activer 2FA" });
            document.getElementById("message_2fa").style.color = "red";
          }
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : redirectToStripe is used to get user stripe account and redirect to
   * input : none
   * output : none
   */
  redirectToStripe = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(
      Urls.urls_api + "stripe/create-customer-portal-session/",
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          window.open(data.customer_portal_session.url, "_blank");
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getUserSubscription is used to get which subscription the user had
   * input : none
   * output : none
   */
  getUserSubscription = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/user-active-subscription/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.length === 0) {
            this.setState({ user_subscription: "Freemium" });
          } else {
            this.setState({ user_subscription: data[0].plan.product.name });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : toTarif is used to redirect the used to the changeinfo page
   * input : none
   * output : none
   */
  toChangeInfo = () => {
    window.location.href = Urls.urls_server + "changeinfo";
  };

  render() {
    if (window.sessionStorage.getItem("photo") === null) {
      return (
        <div id="settings">
          <br />
          <h2 id="titre_section_settings">Tarification</h2>
          <h3>Mon abonnement : {this.state.user_subscription}</h3>
          <Button onClick={this.toTarif} id="settings_button" animated>
            <Button.Content visible>
              <h4>Voir les plans d'abonnement</h4>
            </Button.Content>
            <Button.Content hidden>
              <Icon name="cart" />
            </Button.Content>
          </Button>
          <br />
          <Button onClick={this.redirectToStripe} id="settings_button" animated>
            <Button.Content visible>
              <h4>Gérer mon abonnement</h4>
            </Button.Content>
            <Button.Content hidden>
              <Icon name="cart" />
            </Button.Content>
          </Button>
          <hr id="hrrr" />
          <br />
          <div id="link_section">
            <h3>Email : {this.state.email}</h3>
            <Button onClick={this.toChangeInfo} id="settings_button" animated>
              <Button.Content visible>
                <h4>Informations Personnelles</h4>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="info circle" />
              </Button.Content>
            </Button>
            <br />
            <Button
              onClick={this.handleDeconnexion}
              id="settings_button"
              animated="fade"
            >
              <Button.Content visible>
                <h4 id="deconnexion_button">Se déconnecter</h4>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="sign out alternate" />
              </Button.Content>
            </Button>
          </div>
          <hr id="hrrr" />
          <h2 id="titre_section_settings">API</h2>
          <div id="link_section">
            <Link to="/apiconnexion">
              <Button id="settings_button" animated>
                <Button.Content visible>
                  <h4>Connexion API Plateforme</h4>
                </Button.Content>
                <Button.Content hidden>
                  <Icon name="angle right" />
                </Button.Content>
              </Button>
            </Link>
          </div>
          <hr id="hrrr" />
          <h2 id="titre_section_settings">Sécurité</h2>
          {}
          <h3 id="message_double_auth">
            Double authentification :{" "}
            <b id="message_2fa">{this.state.two_factor_message}</b>
          </h3>
          <div id="link_section">
            <Button onClick={this.openChange2fa} id="settings_button" animated>
              <Button.Content visible>
                <h4>{this.state.button_2fa}</h4>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="lock" />
              </Button.Content>
            </Button>
            <Confirm
              id="confirm_windows"
              content="Voulez-vous vraiment changer votre double authentification ?"
              open={this.state.confirm_state_2fa}
              onCancel={this.closeChange2fa}
              onConfirm={this.change2fa}
            />
            <br />
            <div>
              <Button
                onClick={this.openChangePassword}
                id="settings_button"
                animated
              >
                <Button.Content visible>
                  <h4>Changer son mot de passe</h4>
                </Button.Content>
                <Button.Content hidden>
                  <Icon name="lock" />
                </Button.Content>
              </Button>
              <Confirm
                id="confirm_windows"
                content="Voulez-vous vraiment changer de mot de passe ?"
                open={this.state.confirm_state_password}
                onCancel={this.closeChangePassword}
                onConfirm={this.handleSetNewPassword}
              />
            </div>
            <br />
            <Button onClick={this.toChangeInfo} id="settings_button" animated>
              <Button.Content visible>
                <h4>Changer son mail</h4>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="mail" />
              </Button.Content>
            </Button>
          </div>
          <br />
          <br />
        </div>
      );
    } else {
      return (
        <div id="settings_smartphone">
          <br />
          <h2 id="titre_section_settings">Tarification</h2>
          <h3>Mon abonnement : {this.state.user_subscription}</h3>
          <Button onClick={this.toTarif} id="settings_button" animated>
            <Button.Content visible>
              <h4>Voir les plans d'abonnement</h4>
            </Button.Content>
            <Button.Content hidden>
              <Icon name="cart" />
            </Button.Content>
          </Button>
          <br />
          <Button onClick={this.redirectToStripe} id="settings_button" animated>
            <Button.Content visible>
              <h4>Gérer mon abonnement</h4>
            </Button.Content>
            <Button.Content hidden>
              <Icon name="cart" />
            </Button.Content>
          </Button>
          <hr id="hrrr" />
          <br />
          <div id="link_section">
            <img
              id="profile_photo"
              src={window.sessionStorage.getItem("photo")}
            />
            <h3>Email : {this.state.email}</h3>
            <br />
            <Button
              onClick={this.handleDeconnexion}
              id="settings_button"
              animated="fade"
            >
              <Button.Content visible>
                <h4 id="deconnexion_button">Se déconnecter</h4>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="sign out alternate" />
              </Button.Content>
            </Button>
          </div>
          <hr id="hrrr" />
          <h2 id="titre_section_settings">API</h2>
          <div id="link_section">
            <Link to="/apiconnexion">
              <Button id="settings_button" animated>
                <Button.Content visible>
                  <h4>Connexion API Plateforme</h4>
                </Button.Content>
                <Button.Content hidden>
                  <Icon name="angle right" />
                </Button.Content>
              </Button>
            </Link>
          </div>
          <br />
        </div>
      );
    }
  }
}
