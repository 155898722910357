import React, { Component } from "react";
import "./tarif.css";
import Urls from "../urls/urls";

/**
 * DESCRIPTION : The "Tarif" Component display all the tarif information<br/><br/>
 * STATE :<br/>
 * user_subscription : contains the user subscription<br/>
 * all_subscription : contains all the stripe subscription<br/><br/>
 * RETURN :<br/>
 * tarif page<br/>
 * @version 2.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Tarif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_subscription: "",
      all_subscription: [
        {
          id: "price_1KpV3BEStyqLUku8IQ7tZr7i",
          amount: "8.00",
          currency: "eur",
          interval: "month",
          product: {
            id: "prod_LWY99AXKo4Dz0d",
            name: "NOVICE",
            description: "50 cryptos et 7 plateformes",
            images: [],
          },
        },
        {
          id: "price_1KpV5qEStyqLUku8JVayE6zX",
          amount: "11.00",
          currency: "eur",
          interval: "month",
          product: {
            id: "prod_LWYCVzZE8Zn8Vp",
            name: "PRO",
            description: "100 cryptos et 15 plateformes",
            images: [],
          },
        },
        {
          id: "price_1KpV6OEStyqLUku8IeitG7w1",
          amount: "13.00",
          currency: "eur",
          interval: "month",
          product: {
            id: "prod_LWYCHsA6EFs3D6",
            name: "EXPERT",
            description: "300 cryptos et 30 plateformes",
            images: [],
          },
        },
      ],
    };
  }

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to verify if the user is connected and get the user's subscription
   */
  componentDidMount() {
    if (
      window.sessionStorage.getItem("token") !== null &&
      window.sessionStorage.getItem("uid") !== null
    ) {
      this.getUserSubscription();
    }
    this.getAllSubscription();
  }
  /**
   * function : returnHtmlObject is used to transform string to html object
   * input : _string : the html string
   * output : none
   */
  returnHtmlObject = (_string) => {
    var result = _string.split(".");
    return (
      <div id="description_function">
        <h3>{result[0]}</h3>
        <h3>{result[1]}</h3>
      </div>
    );
  };

  /**
   * function : redirectTo is used to redirect the user on stripe windows according to the price chosen
   * input : _id : the id of the div
   * output : none
   */
  redirectTo = (_id) => {
    if (
      sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server + "connexion";
      return;
    }
    var sub = this.state.user_subscription;
    if (_id === 1 && sub === "Freemium") {
      window.open("https://buy.stripe.com/test_dR64iM7Df3d0fHW6oo", "_blank");
    } else if (_id === 2 && sub === "Freemium") {
      window.open("https://buy.stripe.com/test_8wM02w3mZdRE9jyfYZ", "_blank");
    } else if (_id === 3 && sub === "Freemium") {
      window.open("https://buy.stripe.com/test_5kAg1u0aN28W2Va6oq", "_blank");
    } else {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + window.sessionStorage.getItem("token"),
        },
      };
      fetch(
        Urls.urls_api + "stripe/create-customer-portal-session/",
        requestOptions
      )
        .then((res) =>
          res.json().then((data) => {
            window.open(data.customer_portal_session.url, "_blank");
          })
        )
        .catch((err) => console.log(err));
    }
  };

  /**
   * function : getAllSubscription is used to get all the stripe subscription
   * input : _id : the id of the div
   * output : none
   */
  getAllSubscription = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(Urls.urls_api + "api/plans/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ all_subscription: data });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getUserSubscription is used to get the user subscription
   * and set the div color
   * input : _id : the id of the div
   * output : none
   */
  getUserSubscription = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/user-active-subscription/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.length === 0) {
            this.setState({ user_subscription: "Freemium" });
            document.getElementById("div_tarif_type_1").style.border = "solid";
            document.getElementById("div_tarif_type_1").style.borderBlockColor =
              "white";
          } else {
            this.setState({ user_subscription: data[0].plan.product.name });
            if (data[0].plan.product.name === "NOVICE") {
              document.getElementById("div_tarif_type_2").style.border =
                "solid";
              document.getElementById(
                "div_tarif_type_2"
              ).style.borderBlockColor = "white";
            } else if (data[0].plan.product.name === "PRO") {
              document.getElementById("div_best_tarif_type").style.border =
                "solid";
              document.getElementById(
                "div_best_tarif_type"
              ).style.borderBlockColor = "white";
            } else if (data[0].plan.product.name === "EXPERT") {
              document.getElementById("div_tarif_type_4").style.border =
                "solid";
              document.getElementById(
                "div_tarif_type_4"
              ).style.borderBlockColor = "white";
            }
          }
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : changeMarginDiv is used to set a margin top to the div to avoid the header to hide the div
   * and set the div color
   * input : _id : the id of the div
   * output : none
   */
  changeMarginDiv = () => {
    var headerHeight = document.getElementById("header").clientHeight;
    document
      .getElementById("tarif")
      .style.setProperty("margin-top", headerHeight + "px");
  };

  render() {
    return (
      <div id="tarif">
        <br />
        <div onClick={() => this.redirectTo()} id="div_tarif_type_1">
          <h2>FREEMIUM : Version gratuite !</h2>
          <h3>7 Cryptos</h3>
          <h3>2 plateformes</h3>
        </div>
        <div onClick={() => this.redirectTo(1)} id="div_tarif_type_2">
          <h5 id="prix">{this.state.all_subscription[0].amount}€/mois</h5>
          <h2 id="title_tarif">
            {this.state.all_subscription[0].product.name}
          </h2>
          {this.returnHtmlObject(
            this.state.all_subscription[0].product.description
          )}
        </div>
        <div onClick={() => this.redirectTo(2)} id="div_best_tarif_type">
          <div id="best_prix">
            <h5 id="text_best_price">
              {this.state.all_subscription[1].amount}€/mois
            </h5>
            <h5 id="text_best_price">meilleure vente</h5>
          </div>
          <h2 id="title_tarif">
            {this.state.all_subscription[1].product.name}
          </h2>
          {this.returnHtmlObject(
            this.state.all_subscription[1].product.description
          )}
        </div>
        <div onClick={() => this.redirectTo(3)} id="div_tarif_type_4">
          <h5 id="prix">{this.state.all_subscription[2].amount}€/mois</h5>
          <h2 id="title_tarif">
            {this.state.all_subscription[2].product.name}
          </h2>
          {this.returnHtmlObject(
            this.state.all_subscription[2].product.description
          )}
        </div>
        <br />
        <br />
      </div>
    );
  }
}
