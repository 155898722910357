import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import Urls from "../urls/urls";
import "./Dfa.css";

/**
 * DESCRIPTION : The "Dfa" Component display the second step of login.
 * The user can enter the code that he received by email to validate his double authentification<br/><br/>
 * STATE :<br/>
 * error : contains the error which will be displayed<br/>
 * code : contains the 2fa code entered by the user<br/>
 * RETURN :<br/>
 * 2fa confirmation page<br/>
 * @version 2.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Dfa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      code: "",
    };
    this.handleChangeCode = this.handleChangeCode.bind(this);
  }

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to verify the email and the password is correctly saved in local storage
   */
  componentDidMount() {
    if (
      localStorage.getItem("email") === null &&
      localStorage.getItem("pwd") === null
    ) {
      window.location.href = Urls.urls_server + "connexion";
    }
  }

  /**
   * this function just before the the component will be undisplayed.
   * it is used to remove the password of the local storage
   */
  componentWillUnmount() {
    localStorage.removeItem("pwd");
  }

  /**
   * function : handleChangeCode is used to change code state
   * input : field event
   * output : none
   */
  handleChangeCode(event) {
    this.setState({ code: event.target.value });
  }

  /**
   * function : handleSubmit will verify if the code are correct, the user will be redirect on the load page
   * input : none
   * output : none
   */
  handleSubmit = () => {
    var requestOptions;
    requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: window.localStorage.getItem("email"),
        password: window.localStorage.getItem("pwd"),
        otp_token: this.state.code,
      }),
    };
    fetch(Urls.urls_api + "dj-rest-auth/login/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.key !== undefined) {
            sessionStorage.setItem("token", data.key);
            sessionStorage.setItem("uid", data.user);
            window.location.href = Urls.urls_server + "load";
          } else {
            this.setState({ error: "Veuillez entrer le bon code" });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div id="dfa">
        <h1 id="titre">Double authentification</h1>
        <h3 id="text">
          Un mail vous a été envoyé, veuillez entrer le code indiqué
        </h3>
        <h3 id="text_error">{this.state.error}</h3>
        <Form id="form">
          <Form.Field id="field">
            <label id="label">Code</label>
            <input
              type="text"
              placeholder="Code"
              value={this.state.code}
              onChange={this.handleChangeCode}
            />
          </Form.Field>
          <br />
          <Button id="main_button" type="submit" onClick={this.handleSubmit}>
            Connexion
          </Button>
        </Form>
      </div>
    );
  }
}
