import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import Urls from "../urls/urls";
import "./forgotpwd.css";

/**
 * Description : The class "Forgotpwd" is used when a user wants to reset his password
 * <br/><br/>
 * State : <br/>
 * mail : contains the mail entered by the user<br/>
 * error : contains the error which will be displayed<br/><br/>
 * Return :<br/>
 * the password reset page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Forgotpwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: "",
      error: "",
    };
    this.handleChangeMail = this.handleChangeMail.bind(this);
  }

  /**
   * function : handleChangeMail is used to change mail state
   * input : the field which contain the mail
   * output : none
   */
  handleChangeMail(event) {
    this.setState({ mail: event.target.value });
  }

  /**
   * function : handleSubmit will verify is the mail adress first exist,
   * then send a reset password mail
   * input : none
   * output : none
   */
  handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.mail,
      }),
    };
    fetch(Urls.urls_api + "dj-rest-auth/password/reset/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.detail === "Password reset e-mail has been sent.") {
            window.location.href = Urls.urls_server + "mailalert";
          }
          if (data.email[0] === "Enter a valid email address.") {
            this.setState({
              error: "Veuillez saisir une adresse Email valide.",
            });
          } else {
            this.setState({ error: "Veuillez reéssayer" });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div id="forgotpwd">
        <h1 id="titre">Réinitilisation de mot de passe</h1>
        <h3 id="text">
          Veuillez entrer votre adresse mail, un mail vous sera envoyé pour
          réinitilisé votre mot de passe
        </h3>
        <h3 id="text_error">{this.state.error}</h3>
        <Form id="form">
          <Form.Field id="field">
            <label id="label">Adresse Mail</label>
            <input
              placeholder="Adresse Mail"
              value={this.state.mail}
              onChange={this.handleChangeMail}
            />
          </Form.Field>
          <Button id="main_button" type="submit" onClick={this.handleSubmit}>
            Envoyer
          </Button>
        </Form>
      </div>
    );
  }
}
