import React, { Component } from "react";
import YouTube from "react-youtube";
import "./accueil.css";
import { Button, Icon } from "semantic-ui-react";
import Urls from "../urls/urls";
import Footer from "../footer/footer";

/**
 * Description : The class "Accueil" is the home page component which contain a presentation video and a text<br />
 * STATE :<br/>
 * none<br/> <br/>
 * RETURN :<br/>
 * The home div
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Accueil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_video: "E6XUcCl1NGs",
      last_id_video: "",
    };
  }

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to update the background color of the web app (from #5A5A5A to black)
   */
  componentDidMount() {
    this.getLastVideo();
  }


  // Function uses: This function retrieves the last video from the YouTube API.
// Input: None
// Output: None

getLastVideo = () => {
  // Creating the request options for the API call
  var requestOptions;
  requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  // Fetching the last video from the YouTube API
  fetch(Urls.urls_api + "youtube/last-video", requestOptions)
    .then((res) =>
      res.json().then((data) => {
        // Updating the state with the ID of the last video
        this.setState({ last_id_video: data.items[0].id.videoId });
      })
    )
    .catch((err) => console.log(err));
};

  /**
   * this function just before the the component will be undisplayed.
   * it is used to reset the background color of the web app (from black to #5A5A5A)
   */
  componentDidUpdate() {}

  render() {
    return (
      <div id="home">
        <br />
        <br />
        <br />
        <h2>Présentation</h2>
        <YouTube
          id="youtube_player"
          videoId={this.state.id_video}
          containerClassName="youtube_container"
          onReady={this._onReady}
        />
        <hr />
        <h2>À propos</h2>
        <h4>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ut
          tincidunt risus, vitae pellentesque orci. Donec tincidunt lacus ante,
          eu blandit diam tempus auctor. Aliquam dignissim vulputate urna, non
          fringilla ligula facilisis at. Nam fermentum tincidunt nisl, at
          vehicula justo gravida non. Vestibulum ullamcorper eu augue non
          placerat. Ut eget arcu ac dui bibendum ullamcorper. Curabitur aliquet
          egestas ex eu maximus. Sed egestas nisl eu nisi mollis volutpat. Proin
          sed aliquet diam. Duis commodo lorem eget laoreet egestas. Curabitur
          sapien odio, ullamcorper a accumsan quis, faucibus non neque. Sed
          pharetra in metus ut eleifend. Donec dignissim condimentum laoreet.
          Etiam vel nunc erat. Fusce porta pellentesque nibh, id ornare leo
          congue in. Aenean pellentesque odio et faucibus molestie. Nullam in
          ante sed eros laoreet laoreet sit amet ac ligula. Donec tincidunt
          fringilla bibendum. Phasellus finibus euismod vulputate. Duis vel
          lectus eu velit faucibus venenatis. Nullam in felis lectus. Proin quis
          nulla metus. Praesent in suscipit tellus, at viverra odio. Suspendisse
          potenti. In sed nisl sed risus tempor rhoncus. Praesent et mauris sed
          nulla pretium dictum. Maecenas eu nibh lacinia, imperdiet lacus at,
          viverra mi. Donec ac posuere risus, in porttitor justo. Pellentesque
          vitae nisi turpis. Nunc ut vulputate quam. In condimentum purus augue,
          sed dictum velit consequat sit amet. Aenean ac lectus vitae lectus
          finibus consectetur a quis lorem. Donec imperdiet dapibus ipsum sed
          tempus. Nulla pretium arcu vestibulum lectus eleifend ornare.
          Vestibulum ac metus nec ipsum consectetur laoreet. Proin eget ultrices
          nisl. Sed vestibulum iaculis ipsum, vel tincidunt felis tincidunt id.
          In sed elit ultricies, maximus libero eget, rutrum nisl. In volutpat
          placerat massa, vitae consectetur augue auctor sit amet. Cras ipsum
          velit, aliquam at lobortis quis, viverra sed sem. Class aptent taciti
          sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos. Integer auctor urna posuere, tempus nulla quis, facilisis
          arcu. In condimentum ipsum a porta faucibus. Aenean mollis, felis at
          aliquam viverra, lacus lectus mollis lectus, sed viverra nisi ex ut
          enim. Donec lacinia est arcu, vitae ornare enim viverra et. Nunc in
          dolor volutpat, efficitur neque ut, maximus urna. Ut id ante sagittis,
          venenatis velit aliquet, pharetra diam. Etiam ultrices mattis rhoncus.
          Aliquam sed ornare felis. Etiam vitae mauris lectus. Aenean tempus
          interdum mauris at fringilla. Phasellus sed purus nec velit
          ullamcorper scelerisque. Donec scelerisque nisi neque, at dapibus odio
          aliquam vitae.
        </h4>
        <hr />
        <h2>Dernière video</h2>
        <YouTube
          id="youtube_player1"
          videoId={this.state.last_id_video}
          containerClassName="youtube_container"
          onReady={this._onReady}
        />
        <br />
        <hr />
        <a
          href="https://www.youtube.com/channel/UC_kiCOCi0VDqVTGfmoGC8Ig/featured"
          target="_blank"
        >
          <Button id="button_redirect_youtube" animated>
            <Button.Content visible>Visite ma chaine youtube !</Button.Content>
            <Button.Content hidden>
              <Icon name="youtube" />
            </Button.Content>
          </Button>
        </a>
        <br />
        {(() => {
          if (window.innerWidth < 600) {
            return <Footer />;
          }
        })()}
      </div>
    );
  }
}
