import React, { Component } from "react";
import "./mentions.css";

export default class Mentions extends Component {
  state = {};

  /**
   * Function to change the margin of a div based on the window width
   */
  changeMarginDiv = () => {
    if (window.innerWidth > 600) {
      // Get the height of the header element
      var headerHeight = document.getElementById("header").clientHeight;

      // Set the top margin of the "cgu" element to be below the header
      document
        .getElementById("cgu")
        .style.setProperty("top", headerHeight + 10 + "px");
    }
  };

  componentDidMount() {
    this.changeMarginDiv();
  }

  render() {
    return (
      <div id="cgu">
        <h1 id="cgu_title">Mentions légales du site CryptOrganiz</h1>
        <br />
        <h2>ARTICLE 1 : Les mentions légales</h2>
        <ul>
          <li>Nom de la société :</li>
          <li>Forme juridique :</li>
          <li>Adresse e-mail de contact :</li>
          <li>Numéro d'identification de l'entreprise (SIRET) :</li>
          <li>Nom de l'hébergeur du site :</li>
        </ul>
        <hr></hr>
        <h2>ARTICLE 2 : Les conditions générales d'utilisation</h2>
        <ul>
          <li>
            Objet du site : Le site web Cryptorganiz permet à ses utilisateurs
            d'avoir une visualisation globale de leur portefeuille de
            crypto-monnaies en temps réel.
          </li>
          <li>
            Conditions d'utilisation : En utilisant le site web, l'utilisateur
            accepte les présentes conditions générales d'utilisation sans
            réserve.
          </li>
          <li>
            Modalités d'abonnement : Le site propose plusieurs types
            d'abonnements mensuels en fonction des services souhaités. Les
            modalités d'abonnement, les tarifs et les modalités de paiement sont
            détaillées sur la page dédiée aux abonnements.
          </li>
          <li>
            Responsabilités : Le site ne peut être tenu responsable des pertes
            éventuelles subies par l'utilisateur en raison de l'utilisation de
            ses services.
          </li>
          <li>
            Propriété intellectuelle : Les droits de propriété intellectuelle
            liés au site et à son contenu appartiennent à Cryptorganiz. Toute
            reproduction, distribution ou exploitation non autorisée du site est
            strictement interdite.
          </li>
          <li>
            Résiliation : L'utilisateur peut résilier son abonnement à tout
            moment en suivant la procédure sur le site.
          </li>
        </ul>
        <hr></hr>
        <h2>ARTICLE 3 : Les tarifs et modalités de paiement</h2>
        <ul>
          <li>
            Le site propose plusieurs types d'abonnements mensuels en fonction
            des services souhaités, avec des tarifs variables pouvant être
            modifiés n’importe quand.
          </li>
          <li>Les paiements s'effectuent par carte bancaire.</li>
          <li>
            En cas de résiliation anticipée de l'abonnement, aucun remboursement
            ne sera effectué avant la fin de l’abonnement souscrit.
          </li>
        </ul>
        <hr></hr>
        <h2>ARTICLE 4 : La politique de confidentialité</h2>
        <ul>
          <li>
            Les données personnelles collectées : Le site collecte les données
            personnelles de ses utilisateurs (nom, prénom, adresse e-mail,
            informations de paiement, etc.) pour les besoins de la gestion de
            leur abonnement.
          </li>
          <li>
            Les finalités du traitement des données : Les données personnelles
            des utilisateurs sont utilisées uniquement pour la gestion de leur
            abonnement.
          </li>
          <li>
            La durée de conservation des données : Les données personnelles des
            utilisateurs sont conservées pendant la durée de leur abonnement,
            puis supprimées.
          </li>
          <li>
            La sécurité des données : Le site s'engage à assurer la sécurité des
            données personnelles de ses utilisateurs en mettant en place des
            mesures de sécurité appropriées pour empêcher leur accès non
            autorisé, leur perte ou leur altération.
          </li>
          <li>
            Les droits des utilisateurs : Les utilisateurs disposent d'un droit
            d'accès, de rectification et de suppression de leurs données
            personnelles, ainsi qu'un droit à la portabilité de ces données. Ils
            peuvent également s'opposer au traitement de leurs données
            personnelles pour des motifs légitimes.
          </li>
          <li>
            Les destinataires des données : Les données personnelles des
            utilisateurs sont destinées uniquement à Cryptorganiz et stripe pour
            les besoins de la gestion de leur abonnement.
          </li>
          <li>
            Les cookies : Le site utilise des cookies pour améliorer
            l'expérience de l'utilisateur. Les utilisateurs peuvent configurer
            leur navigateur pour bloquer les cookies s'ils le souhaitent.
          </li>
        </ul>
        <hr></hr>
        <h2>ARTICLE 5 : La politique de cookies</h2>
        <ul>
          <li>
            Les types de cookies utilisés : Le site utilise des cookies de
            session et des cookies persistants pour améliorer l'expérience de
            l'utilisateur.
          </li>
          <li>
            Les finalités des cookies : Les cookies sont utilisés pour mémoriser
            les préférences de l'utilisateur, pour faciliter la navigation sur
            le site et pour améliorer la qualité des services proposés.
          </li>
          <li>
            Les données collectées : Les cookies collectent des données anonymes
            sur l'utilisation du site par l'utilisateur (pages visitées, durée
            de la visite, etc.).
          </li>
          <li>
            Les cookies tiers : Le site peut utiliser des cookies tiers (Google
            Analytics, Facebook, etc.) pour collecter des données statistiques
            sur l'utilisation du site par l'utilisateur.
          </li>
          <li>
            Le consentement de l'utilisateur : L'utilisation des cookies est
            soumise au consentement de l'utilisateur, qui peut configurer son
            navigateur pour bloquer les cookies s'il le souhaite.
          </li>
        </ul>
      </div>
    );
  }
}
