import React, { Component } from "react";
import { Link } from "react-router-dom";
import Recap from "../recap/recap";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import "./transaction.css";
import Urls from "../urls/urls";
import Wait from "../wait/wait";

/**
 * DESCRIPTION : The "Transaction" Component display the page where user can add manual transaction.
 * This list of transaction is links to cutom account(exchange manualy added)
 * <br/><br/>
 * STATE :<br/>
 * transaction : contains the list of transaction with exchange, amount, date and man<br/>
 * error_message : contains the error which will be displayed<br/>
 * list_plateform_total : contains the verification of the confirmation field<br/>
 * loading : is used to wait to all data set
 * modal_crypto_state : contains the state of the crypto modal<br/>
 * list_crypto_total : contains the list of all crypto<br/>
 * crypto : contains the crypto selected<br/>
 * quantity : contains the quantity of crypto selected<br/>
 * error_crypto : contains the error message in case of problem
 * <br/><br/>
 * RETURN :<br/>
 * Transaction page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: [],
      error_message: "",
      list_plateform_total: [],
      loading: true,
      modal_crypto_state: false,
      list_crypto_total: [],
      list_crypto_refresh: [],
      crypto: "",
      quantity: "",
      error_crypto: "",
      input_type_date: "text",
      input_type_heure: "text",
      modal_add_custom: false,
      modal_add_api_before: false,
      modal_add_api_after: false,
      selected_crypto: 0,
      crypto_to_add: {},
      padding_table: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
    };
    this.changeCryptoState = this.changeCryptoState.bind(this);
  }
  intervalId;

  /**
   * function : changeCryptoState is used to change crypto modal state
   * input : _bool : represent the new state of crypto modal
   * output : none
   */
  changeCryptoState = (_bool) => {
    this.setState({ modal_crypto_state: _bool });
  };

  /**
   * function : selectionCrypto is used get and store the crypto selected
   * input : none
   * output : none
   */
  selectionCrypto = () => {
    var crypto_name = document.getElementById("selected_crypto").value;
    var valid = false;
    var crypto_data;
    if (crypto_name === "") {
      this.setState({ error_crypto: "Veuillez entrer la crypto" });
      return;
    }
    this.state.list_crypto_total.map((crypto) => {
      if (crypto.name === crypto_name) {
        crypto_data = crypto;
        valid = true;
      }
    });
    if (valid === false) {
      this.setState({ error_crypto: "Veuillez entrer une crypto existante" });
      return;
    }
    this.setState({ crypto: crypto_data });
    this.changeCryptoState(false);
  };

  /**
   * function : selectionCrypto is used get and store the crypto selected in phone
   * input : none
   * output : none
   */
  selectionCryptoTel = () => {
    var crypto_name = document.getElementById("selected_crypto").value;
    var quantity = document.getElementById("selected_crypto").value;
    var valid = false;
    var crypto_data;
    if (crypto_name === "" || quantity === "") {
      this.setState({
        error_crypto: "Veuillez entrer la crypto et la quantité",
      });
      return;
    }
    this.state.list_crypto_total.map((crypto) => {
      if (crypto.name === crypto_name) {
        crypto_data = crypto;
        valid = true;
      }
    });
    if (valid === false) {
      this.setState({ error_crypto: "Veuillez entrer une crypto existante" });
      return;
    }
    this.setState({ crypto: crypto_data });
    this.setState({ quantity: quantity });
    this.changeCryptoState(false);
  };

  /**
   * function : getAllCoin is used to get the list of all possible coin
   * input : none
   * output : none
   */
  getAllCoin = () => {
    var crypto_present = [];
    const requestOptions = {
      method: "GET",
    };
    fetch(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&per_page=250",
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          data.map((crypto) => {
            crypto_present.push(crypto.name);
          });
          this.setState({
            list_crypto_total: data,
          });
          fetch("https://api.coingecko.com/api/v3/coins/list", requestOptions)
            .then((res) =>
              res.json().then((data) => {
                data.map((crypto) => {
                  if (crypto_present.indexOf(crypto.name) === -1) {
                    this.state.list_crypto_total.push(crypto);
                  }
                });
              })
            )
            .catch((err) => console.log(err));
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getAllPlatform is used to get all exchange added by the user.
   * input : none
   * output : none
   */
  getAllPlatform = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/custom-account/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          data.map((plat) => {
            this.state.list_plateform_total.push({
              id: plat.id,
              name: plat.name,
              exchange: false,
            });
          });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getAccountExchange is used to get all exchange connected using API
   * input : none
   * output : none
   */
  getAccountExchange = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/exchange-account/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          data.map((plat) => {
            this.state.list_plateform_total.push({
              id: plat.id,
              name: plat.exchange.name,
              creation_date: plat.creation_date,
              exchange: true,
              exchange_id: plat.exchange.id,
            });
          });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : cryptoVerificiation is used to verify if the crypto is present in the API
   * input : none
   * output : none
   */
  cryptoVerificiation = () => {
    var selected_crypto = document
      .getElementById("selected_crypto")
      .value.toLowerCase();
    var crypto_coin_gecko;
    var bool = true;
    this.state.list_crypto_total.map((crypto) => {
      if (
        (crypto.symbol.toLowerCase() === selected_crypto ||
          crypto.id.toLowerCase() === selected_crypto ||
          crypto.name.toLowerCase() === selected_crypto) &&
        bool
      ) {
        crypto_coin_gecko = crypto;
        bool = false;
      }
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(
      Urls.urls_api +
        "api/user-added-crypto/?coingecko-id=" +
        crypto_coin_gecko.id,
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          if (data.length !== 0) {
            this.setState({ selected_crypto: data[0] });
          } else {
            this.setState({ selected_crypto: 0 });
            this.setState({
              crypto_to_add: {
                name: crypto_coin_gecko.name,
                symbol: crypto_coin_gecko.symbol,
                coingecko_id: crypto_coin_gecko.id,
                stablecoin: false,
              },
            });
          }
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * function : deleteTransaction is used to delete a transaction.
   * input : _id : id of the transaction which will be deleted
   * output : none
   */
  deleteTransaction = (_id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/fiat-trades/" + _id + "/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.getTransaction();
        })
      )
      .catch((err) => {
        this.getTransaction();
        console.log(err);
      });
  };

  /**
   * function : addUserAddedCrypto is used to add a crypto.
   * input : _id : id of the transaction which will be deleted
   * output : none
   */
  addUserAddedCrypto = (_crypto) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        cryptocurrency: _crypto,
      }),
    };
    fetch(Urls.urls_api + "api/user-added-crypto/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ selected_crypto: data });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : addAchat is used to add a buying transaction.
   * input : none
   * output : none
   */
  addAchat = (_exchange) => {
    // Get input values from the form
    var plateform = document.getElementById("input_plateform").value;
    var montant = document.getElementById("input_montant").value;
    var input_date = document.getElementById("input_date").value;
    var selected_quantity = document.getElementById("selected_quantity").value;
    var input_hour = document.getElementById("input_time").value;
    var date = input_date + "T" + input_hour + ":00";
    var id_plat;
    var bad_date = false;

    // If the selected cryptocurrency is a new addition, call a function to add it
    if (this.state.selected_crypto === 0) {
      this.addUserAddedCrypto(this.state.crypto_to_add);
    }

    // Wait for the new cryptocurrency to be added, then proceed
    setTimeout(() => {
      // Find the ID of the selected platform
      this.state.list_plateform_total.map((plat) => {
        if (plat.name === plateform) {
          id_plat = plat.id;
          if (_exchange) {
            // Check if the selected date is after the platform's creation date
            if (new Date(date) > new Date(plat.creation_date)) {
              this.setState({
                error_message:
                  "Veuillez ajouter une transaction anterieur à la date d'ajout de l'exchange",
              });
              bad_date = true;
            } else {
              this.setState({
                error_message: "",
              });
            }
          }
        }
      });

      // If the date is invalid, stop execution
      if (bad_date) {
        return;
      }

      // If the platform ID is null, display an error message and stop execution
      if (id_plat == null) {
        this.setState({
          error_message:
            "Cette plateforme n'a pas été ajoutée, rendez vous sur la page Transaction",
        });
        return;
      }

      // Validate the form inputs
      if (
        plateform === "" ||
        montant === "" ||
        input_date === "" ||
        this.state.selected_crypto === "" ||
        selected_quantity === "" ||
        input_hour === ""
      ) {
        this.setState({
          error_message:
            "Veuillez remplir le formulaire ou entrer des données valides",
        });
        return;
      }

      // Prepare the request options based on whether it's an exchange or custom account
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + window.sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          date: date,
          fiat_value: montant,
          custom_user_added_cryptocurrency_id: this.state.selected_crypto.id,
          quantity: selected_quantity,
        }),
      };

      if (_exchange) {
        requestOptions.body.exchange_account_id = id_plat;
        requestOptions.body.trade_type = 1;
      } else {
        requestOptions.body.custom_account_id = id_plat;
        requestOptions.body.trade_type = 1;
      }

      // Send the POST request to add the transaction
      fetch(Urls.urls_api + "api/fiat-trades/", requestOptions)
        .then((res) =>
          res.json().then((data) => {
            // Reset the modals and get the updated transaction data
            this.setState({ modal_add_api_before: false });
            this.setState({ modal_add_api_after: false });
            this.setState({ modal_add_custom: false });
            this.getTransaction();
          })
        )
        .catch((err) => console.log(err));
    }, 1000);
  };

  /**
   * function : addVente is used to add a selling transaction.
   * input : none
   * output : none
   */
  addVente = (_exchange) => {
    // Get the values from input fields
    var plateform = document.getElementById("input_plateform").value;
    var montant = document.getElementById("input_montant").value;
    var input_date = document.getElementById("input_date").value;
    var selected_quantity = document.getElementById("selected_quantity").value;
    var input_hour = document.getElementById("input_time").value;

    // Construct the date string
    var date = input_date + "T" + input_hour + ":00";

    var id_plat;
    var bad_date = false;

    if (this.state.selected_crypto === 0) {
      // Call a function to add the user-added crypto
      this.addUserAddedCrypto(this.state.crypto_to_add);
    }

    setTimeout(() => {

      // Find the matching platform based on the name
      this.state.list_plateform_total.map((plat) => {
        if (plat.name === plateform) {
          id_plat = plat.id;

          if (_exchange) {
            // Check if the transaction date is after the platform's creation date
            if (new Date(date) > new Date(plat.creation_date)) {
              this.setState({
                error_message:
                  "Veuillez ajouter une transaction anterieur à la date d'ajout de l'exchange",
              });
              bad_date = true;
            } else {
              this.setState({
                error_message: "",
              });
            }
          }
        }
      });

      // If the date is bad, return without further execution
      if (bad_date) {
        return;
      }

      if (id_plat == null) {
        // Display an error message if the platform is not found
        this.setState({
          error_message:
            "Cette plateforme n'a pas été ajoutée, rendez vous sur la page Transaction",
        });
        return;
      }

      if (
        plateform === "" ||
        montant === "" ||
        input_date === "" ||
        this.state.selected_crypto === "" ||
        selected_quantity === "" ||
        input_hour === ""
      ) {
        // Display an error message if any required fields are empty
        this.setState({
          error_message:
            "Veuillez remplir le formulaire ou entrer des données valides",
        });
        return;
      }

      if (_exchange) {
        // Prepare the request options for an exchange transaction
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + window.sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            exchange_account_id: id_plat,
            trade_type: 2,
            date: date,
            fiat_value: montant,
            custom_user_added_cryptocurrency_id: this.state.selected_crypto.id,
            quantity: selected_quantity,
          }),
        };
      } else {
        // Prepare the request options for a custom transaction
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + window.sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            custom_account_id: id_plat,
            trade_type: 2,
            date: date,
            fiat_value: montant,
            custom_user_added_cryptocurrency_id: this.state.selected_crypto.id,
            quantity: selected_quantity,
          }),
        };
      }

      // Send the API request to create the transaction
      fetch(Urls.urls_api + "api/fiat-trades/", requestOptions)
        .then((res) =>
          res.json().then((data) => {
            // Reset modal states and fetch transactions
            this.setState({ modal_add_api_before: false });
            this.setState({ modal_add_api_after: false });
            this.setState({ modal_add_custom: false });
            this.getTransaction();
          })
        )
        .catch((err) => console.log(err));
    }, 1000);
  };

  /**
   * function : getTransaction is used to get all the existing transaction from the server.
   * input : none
   * output : none
   */
  getTransaction = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/fiat-trades/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ loading: false });
          this.setState({ transaction: data });
          data.map((api) => {
            this.state.padding_table.slice(0, -1);
          });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getCryptoExchange is used to get the list of crypto available on the selected platform.
   * input : none
   * output : none
   */
  getCryptoExchange = () => {
    var plateform = document.getElementById("input_plateform_api").value;
    var id_plat;
    this.state.list_plateform_total.map((plat) => {
      if (
        plat.name.toLowerCase() === plateform.toLowerCase() &&
        plat.exchange
      ) {
        id_plat = plat.exchange_id;
        this.setState({ error_message: "" });
      } else {
        this.setState({
          error_message: "Veuillez entrer des informations valides",
        });
        this.setState({ list_crypto_refresh: [] });
        return;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + window.sessionStorage.getItem("token"),
        },
      };
      fetch(
        Urls.urls_api + "api/exchange-market-fiat/flat/?exchange_id=" + id_plat,
        requestOptions
      )
        .then((res) =>
          res.json().then((data) => {
            this.setState({ list_crypto_refresh: data });
          })
        )
        .catch((err) => console.log(err));
    });
  };

  /**
   * function : refreshTansactionApi is used to refresh the list of crypto available on the selected platform.
   * input : none
   * output : none
   */
  refreshTansactionApi = () => {
    var plateform = document.getElementById("input_plateform_api").value;
    var cryptoc = document.getElementById("selected_crypto_api").value;
    var id_plat;
    var trigramme_crypto;
    this.state.list_plateform_total.map((plat) => {
      if (plat.name.toLowerCase() === plateform.toLowerCase()) {
        id_plat = plat.id;
      }
    });
    this.state.list_crypto_refresh.map((crypto) => {
      if (crypto.toLowerCase() === cryptoc.toLowerCase()) {
        trigramme_crypto = crypto.toUpperCase();
      }
    });
    if (id_plat === undefined || trigramme_crypto === undefined) {
      this.setState({
        error_message: "Veuillez entrer des informations valides",
      });
      return;
    }
    this.setState({ error_message: "" });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(
      Urls.urls_api +
        "crypto/exchange-account/" +
        id_plat +
        "/fiat-trades/refresh?symbol=" +
        trigramme_crypto +
        "/EUR",
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          this.getTransaction();
          this.setState({ modal_add_api_before: false });
          this.setState({ modal_add_api_after: false });
          this.setState({ modal_add_custom: false });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getDate is used to get the date of a string.
   * input : _date : string to convert
   * output : none
   */
  getDate = (_date) => {
    var result = _date.split("T")[0];
    var jour = result.split("-")[2];
    var mois = result.split("-")[1];
    var annees = result.split("-")[0];
    return jour + "/" + mois + "/" + annees;
  };

  /**
   * function : getHour is used to get the date of a string.
   * input : _date : string to convert
   * output : none
   */
  getHour = (_date) => {
    var heure = _date.split("T")[1];
    return heure.slice(0, 5);
  };

  /**
   * function : changeMarginDiv is used to adjust the top margin of the main div.
   * input : none
   * output : none
   */
  changeMarginDiv = () => {
    if (window.innerWidth > 600) {
      var headerHeight = document.getElementById("header").clientHeight;
      var recapHeight = document.getElementById("recap").clientHeight;
      document
        .getElementById("transaction")
        .style.setProperty("top", headerHeight + recapHeight + 10 + "px");
    } else {
      var headerHeight =
        document.getElementById("header_smartphone").clientHeight;
      var recapHeight =
        document.getElementById("recap_smartphone").clientHeight;
      document
        .getElementById("transaction_tel")
        .style.setProperty("top", headerHeight + recapHeight + 10 + "px");
    }
  };

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to get all plateform and transaction and change the top margin
   */
  componentDidMount() {
    if (
      window.sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server;
    } else {
      this.getAllPlatform();
      this.getAccountExchange();
      this.getTransaction();
      this.getAllCoin();
      this.intervalId = setInterval(() => {
        this.changeMarginDiv();
      }, 1000);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 15000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  componentDidCatch() {
    clearInterval(this.intervalId);
  }

  render() {
    if (this.state.loading) {
      return (
        <div id="wait_container">
          <Recap />
          <Wait />
        </div>
      );
    }
    if (window.innerWidth > 600) {
      return (
        <div className="fade-in" id="transaction">
          <Recap />
          <br />
          <br />
          <Link id="link_to_api_connect" to="/apiconnexion">
            <Button id="button_to_api_connect" animated="vertical">
              <Button.Content visible>
                <h4>API : Connecte tes plateformes ici</h4>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="angle right" />
              </Button.Content>
            </Button>
          </Link>
          <br />
          <div id="div_button_transaction">
            <Modal
              id="modal_transaction"
              onClose={() => {
                this.setState({ modal_add_custom: false });
                this.setState({ error_message: "" });
              }}
              onOpen={() => this.setState({ modal_add_custom: true })}
              open={this.state.modal_add_custom}
              trigger={
                <Button animated>
                  <Button.Content visible>
                    Ajout transaction custom
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="div_modal_transaction_custom">
                <h3 id="text_error">{this.state.error_message}</h3>
                <h4>Transaction Custom</h4>
                <br />
                <div id="transaction_time">
                  <input
                    id="input_date"
                    placeholder="Date"
                    type={this.state.input_type_date}
                    onFocus={() => this.setState({ input_type_date: "date" })}
                  />
                  <input
                    id="input_time"
                    type={this.state.input_type_heure}
                    placeholder="Heure"
                    onFocus={() => this.setState({ input_type_heure: "time" })}
                  />
                </div>
                <br />
                <div id="plateform_montant">
                  <input
                    id="input_plateform"
                    list="plateforme"
                    onChange={this.handleChangePlateform}
                    placeholder="Plateforme"
                  />
                  <datalist id="plateforme">
                    {this.state.list_plateform_total.map((plateform) => {
                      if (!plateform.exchange) {
                        return (
                          <option value={plateform.name}>
                            {plateform.name}
                          </option>
                        );
                      }
                    })}
                  </datalist>
                  <input
                    id="input_montant"
                    type="number"
                    placeholder="Montant(€)"
                    min="0"
                  />
                </div>
                <br />
                <div id="crypto_quantity">
                  <input
                    id="selected_crypto"
                    list="crypto_list"
                    onChange={() => {
                      this.changeCryptoState;
                      this.cryptoVerificiation();
                    }}
                    placeholder="Crypto"
                    pattern=""
                  />
                  <datalist id="crypto_list">
                    {this.state.list_crypto_total.map((crypto) => (
                      <option value={crypto.name}>
                        {crypto.symbol.toUpperCase()}
                      </option>
                    ))}
                  </datalist>
                  <input
                    id="selected_quantity"
                    type="number"
                    placeholder="Quantité"
                    min="0"
                  />
                </div>
                <br />
                <div id="transaction_form_bottom">
                  <Button
                    onClick={() => this.addAchat(false)}
                    id="achat_crypto"
                  >
                    Achat
                    <br />
                    Crypto
                  </Button>
                  <Button
                    onClick={() => this.addVente(false)}
                    id="vente_crypto"
                  >
                    Vente
                    <br />
                    Crypto
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
              id="modal_transaction"
              onClose={() => {
                this.setState({ modal_add_api_before: false });
                this.setState({ error_message: "" });
              }}
              onOpen={() => this.setState({ modal_add_api_before: true })}
              open={this.state.modal_add_api_before}
              trigger={
                <Button
                  title="Cliquez ici pour ajouter vos transactions passées effectuées sur les exchanges que vous avez connectés. 
                Rentrer les transactions antérieures à la date d'ajout de l'exchange en question sur l'application web CryptOrganiz"
                  animated
                >
                  <Button.Content visible>
                    Ajout transaction exchange avant date d'ajout
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="div_modal_transaction_custom">
                <h3 id="text_error">{this.state.error_message}</h3>
                <h4>Transaction API avant date d'ajout</h4>
                <br />
                <div id="transaction_time">
                  <input
                    id="input_date"
                    placeholder="Date"
                    type={this.state.input_type_date}
                    onFocus={() => this.setState({ input_type_date: "date" })}
                  />
                  <input
                    id="input_time"
                    type={this.state.input_type_heure}
                    placeholder="Heure"
                    onFocus={() => this.setState({ input_type_heure: "time" })}
                  />
                </div>
                <br />
                <div id="plateform_montant">
                  <input
                    id="input_plateform"
                    list="plateforme"
                    onChange={this.handleChangePlateform}
                    placeholder="Plateforme"
                  />
                  <datalist id="plateforme">
                    {this.state.list_plateform_total.map((plateform) => {
                      if (plateform.exchange) {
                        return (
                          <option value={plateform.name}>
                            {plateform.name}
                          </option>
                        );
                      }
                    })}
                  </datalist>
                  <input
                    id="input_montant"
                    type="number"
                    placeholder="Montant(€)"
                    min="0"
                  />
                </div>
                <br />
                <div id="crypto_quantity">
                  <input
                    id="selected_crypto"
                    list="crypto_list"
                    onChange={this.changeCryptoState}
                    placeholder="Crypto"
                    pattern=""
                  />
                  <datalist id="crypto_list">
                    {this.state.list_crypto_total.map((crypto) => (
                      <option value={crypto.name}>
                        {crypto.symbol.toUpperCase()}
                      </option>
                    ))}
                  </datalist>
                  <input
                    id="selected_quantity"
                    type="number"
                    placeholder="Quantité"
                    min="0"
                  />
                </div>
                <br />
                <div id="transaction_form_bottom">
                  <Button onClick={() => this.addAchat(true)} id="achat_crypto">
                    Achat
                    <br />
                    Crypto
                  </Button>
                  <Button onClick={() => this.addVente(true)} id="vente_crypto">
                    Vente
                    <br />
                    Crypto
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
              id="modal_transaction"
              onClose={() => {
                this.setState({ modal_add_api_after: false });
                this.setState({ error_message: "" });
              }}
              onOpen={() => this.setState({ modal_add_api_after: true })}
              open={this.state.modal_add_api_after}
              trigger={
                <Button animated>
                  <Button.Content visible>
                    Refresh transation exchange
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="div_modal_transaction_refresh">
                <h3 id="text_error">{this.state.error_message}</h3>
                <h4>Refresh transation exchange</h4>
                <br />
                <div id="modal_transaction_refresh_input">
                  <input
                    id="input_plateform_api"
                    list="plateforme"
                    onChange={() => this.getCryptoExchange()}
                    placeholder="Plateforme"
                  />
                  <br />
                  <input
                    id="selected_crypto_api"
                    list="crypto_list"
                    onChange={this.changeCryptoState}
                    placeholder="Crypto"
                    pattern=""
                  />
                  <datalist id="crypto_list">
                    {this.state.list_crypto_refresh.map((crypto) => (
                      <option value={crypto}>{crypto.toUpperCase()}</option>
                    ))}
                  </datalist>
                  <datalist id="plateforme">
                    {this.state.list_plateform_total.map((plateform) => {
                      if (plateform.exchange) {
                        return (
                          <option value={plateform.name}>
                            {plateform.name}
                          </option>
                        );
                      }
                    })}
                  </datalist>
                </div>
                <br />
                <Button
                  onClick={() => this.refreshTansactionApi()}
                  animated="vertical"
                >
                  <Button.Content visible>
                    API : Actualise tes transactions
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              </div>
            </Modal>
          </div>
          <br />
          <table id="table_transaction">
            <thead>
              <th>Plateforme</th>
              <th>Date</th>
              <th>Heure</th>
              <th>Montant</th>
              <th>Plus Value</th>
              <th>Valeur du portefeuille lors de la cession</th>
              <th>Prix total d'acquisition</th>
              <th>Fraction de capital</th>
              <th>Supprimer</th>
            </thead>
            <tbody>
              {this.state.transaction.map((transac) => (
                <tr>
                  {(() => {
                    if (transac.custom_account !== null) {
                      return <td>{transac.custom_account.name}</td>;
                    }
                    return (
                      <td>
                        {transac.exchange_account.exchange.name}
                        <sup id="sup_plateforme">API</sup>
                      </td>
                    );
                  })()}
                  <td>{this.getDate(transac.date)}</td>
                  <td>{this.getHour(transac.date)}</td>
                  {(() => {
                    if (transac.trade_type == 1) {
                      return (
                        <td id="achat_field">
                          {transac.fiat_value.toFixed(2)} €
                        </td>
                      );
                    }
                    return (
                      <td id="vente_field">
                        {transac.fiat_value.toFixed(2)} €
                      </td>
                    );
                  })()}
                  {(() => {
                    if (transac.capital_gain !== null) {
                      return <td>{transac.capital_gain.toFixed(2)} €</td>;
                    }
                    return <td></td>;
                  })()}
                  {(() => {
                    if (transac.portfolio_value_on_sale !== null) {
                      return (
                        <td>{transac.portfolio_value_on_sale.toFixed(2)} €</td>
                      );
                    }
                    return <td></td>;
                  })()}
                  {(() => {
                    if (transac.total_acquisition_price !== null) {
                      return (
                        <td>{transac.total_acquisition_price.toFixed(2)} €</td>
                      );
                    }
                    return <td></td>;
                  })()}
                  <td>{transac.capital_fraction}</td>
                  <td>
                    <Button
                      id="button_delete_plateform"
                      onClick={() => this.deleteTransaction(transac.id)}
                      size="mini"
                      icon
                    >
                      <h5>X</h5>
                    </Button>
                  </td>
                </tr>
              ))}
              {this.state.padding_table.map((pad) => (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div id="transaction_tel">
          <Recap />
          <Link id="link_to_api_connect" to="/apiconnexion">
            <Button id="button_to_api_connect" animated="vertical">
              <Button.Content visible>
                <h4>API : Connecte tes plateformes ici</h4>
              </Button.Content>
              <Button.Content hidden>
                <Icon name="angle right" />
              </Button.Content>
            </Button>
          </Link>
          <br />
          <div id="div_button_transaction">
            <Modal
              id="modal_transaction"
              onClose={() => {
                this.setState({ modal_add_custom: false });
                this.setState({ error_message: "" });
              }}
              onOpen={() => this.setState({ modal_add_custom: true })}
              open={this.state.modal_add_custom}
              trigger={
                <Button animated>
                  <Button.Content visible>
                    Ajout transaction custom
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="div_modal_transaction_custom">
                <h3 id="text_error">{this.state.error_message}</h3>
                <h4>Transaction Custom</h4>
                <br />
                <div id="transaction_time">
                  <input
                    id="input_date"
                    placeholder="Date"
                    type={this.state.input_type_date}
                    onFocus={() => this.setState({ input_type_date: "date" })}
                  />
                  <input
                    id="input_time"
                    type={this.state.input_type_heure}
                    placeholder="Heure"
                    onFocus={() => this.setState({ input_type_heure: "time" })}
                  />
                </div>
                <br />
                <div id="plateform_montant">
                  <input
                    id="input_plateform"
                    list="plateforme"
                    onChange={this.handleChangePlateform}
                    placeholder="Plateforme"
                  />
                  <datalist id="plateforme">
                    {this.state.list_plateform_total.map((plateform) => {
                      if (!plateform.exchange) {
                        return (
                          <option value={plateform.name}>
                            {plateform.name}
                          </option>
                        );
                      }
                    })}
                  </datalist>
                  <input
                    id="input_montant"
                    type="number"
                    placeholder="Montant(€)"
                    min="0"
                  />
                </div>
                <br />
                <div id="crypto_quantity">
                  <input
                    id="selected_crypto"
                    list="crypto_list"
                    onChange={() => {
                      this.changeCryptoState;
                      this.cryptoVerificiation();
                    }}
                    placeholder="Crypto"
                    pattern=""
                  />
                  <datalist id="crypto_list">
                    {this.state.list_crypto_total.map((crypto) => (
                      <option value={crypto.name}>
                        {crypto.symbol.toUpperCase()}
                      </option>
                    ))}
                  </datalist>
                  <input
                    id="selected_quantity"
                    type="number"
                    placeholder="Quantité"
                    min="0"
                  />
                </div>
                <br />
                <div id="transaction_form_bottom">
                  <Button
                    onClick={() => this.addAchat(false)}
                    id="achat_crypto"
                  >
                    Achat
                    <br />
                    Crypto
                  </Button>
                  <Button
                    onClick={() => this.addVente(false)}
                    id="vente_crypto"
                  >
                    Vente
                    <br />
                    Crypto
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
              id="modal_transaction"
              onClose={() => {
                this.setState({ modal_add_api_before: false });
                this.setState({ error_message: "" });
              }}
              onOpen={() => this.setState({ modal_add_api_before: true })}
              open={this.state.modal_add_api_before}
              trigger={
                <Button animated>
                  <Button.Content visible>
                    Ajout transaction exchange avant date d'ajout
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="div_modal_transaction_custom">
                <h3 id="text_error">{this.state.error_message}</h3>
                <h4>Transaction API avant date d'ajout</h4>
                <br />
                <div id="transaction_time">
                  <input
                    id="input_date"
                    placeholder="Date"
                    type={this.state.input_type_date}
                    onFocus={() => this.setState({ input_type_date: "date" })}
                  />
                  <input
                    id="input_time"
                    type={this.state.input_type_heure}
                    placeholder="Heure"
                    onFocus={() => this.setState({ input_type_heure: "time" })}
                  />
                </div>
                <br />
                <div id="plateform_montant">
                  <input
                    id="input_plateform"
                    list="plateforme"
                    onChange={this.handleChangePlateform}
                    placeholder="Plateforme"
                  />
                  <datalist id="plateforme">
                    {this.state.list_plateform_total.map((plateform) => {
                      if (plateform.exchange) {
                        return (
                          <option value={plateform.name}>
                            {plateform.name}
                          </option>
                        );
                      }
                    })}
                  </datalist>
                  <input
                    id="input_montant"
                    type="number"
                    placeholder="Montant(€)"
                    min="0"
                  />
                </div>
                <br />
                <div id="crypto_quantity">
                  <input
                    id="selected_crypto"
                    list="crypto_list"
                    onChange={this.changeCryptoState}
                    placeholder="Crypto"
                    pattern=""
                  />
                  <datalist id="crypto_list">
                    {this.state.list_crypto_total.map((crypto) => (
                      <option value={crypto.name}>{crypto.name}</option>
                    ))}
                  </datalist>
                  <input
                    id="selected_quantity"
                    type="number"
                    placeholder="Quantité"
                    min="0"
                  />
                </div>
                <br />
                <div id="transaction_form_bottom">
                  <Button onClick={() => this.addAchat(true)} id="achat_crypto">
                    Achat
                    <br />
                    Crypto
                  </Button>
                  <Button onClick={() => this.addVente(true)} id="vente_crypto">
                    Vente
                    <br />
                    Crypto
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
              id="modal_transaction"
              onClose={() => {
                this.setState({ modal_add_api_after: false });
                this.setState({ error_message: "" });
              }}
              onOpen={() => this.setState({ modal_add_api_after: true })}
              open={this.state.modal_add_api_after}
              trigger={
                <Button animated>
                  <Button.Content visible>
                    Refresh transation exchange
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              }
            >
              <div id="div_modal_transaction_refresh">
                <h3 id="text_error">{this.state.error_message}</h3>
                <h4>Transaction API après date d'ajout</h4>
                <br />
                <div id="modal_transaction_refresh_input">
                  <input
                    id="input_plateform_api"
                    list="plateforme"
                    onChange={() => this.getCryptoExchange()}
                    placeholder="Plateforme"
                  />
                  <br />
                  <input
                    id="selected_crypto_api"
                    list="crypto_list"
                    onChange={this.changeCryptoState}
                    placeholder="Crypto"
                    pattern=""
                  />
                  <datalist id="crypto_list">
                    {this.state.list_crypto_refresh.map((crypto) => (
                      <option value={crypto.name}>
                        {crypto.symbol.toUpperCase()}
                      </option>
                    ))}
                  </datalist>
                  <datalist id="plateforme">
                    {this.state.list_plateform_total.map((plateform) => {
                      if (plateform.exchange) {
                        return (
                          <option value={plateform.name}>
                            {plateform.name}
                          </option>
                        );
                      }
                    })}
                  </datalist>
                </div>
                <br />
                <Button
                  onClick={() => this.refreshTansactionApi()}
                  animated="vertical"
                >
                  <Button.Content visible>
                    API : Actualise tes transactions
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="angle right" />
                  </Button.Content>
                </Button>
              </div>
            </Modal>
          </div>
          <br />
          <table id="table_transaction">
            <thead>
              <th>Plateforme</th>
              <th>Date</th>
              <th>Montant</th>
              <th>Plus Value</th>
            </thead>
            <tbody>
              {this.state.transaction.map((transac) => (
                <tr>
                  {(() => {
                    if (transac.custom_account !== null) {
                      return <td>{transac.custom_account.name}</td>;
                    }
                    return (
                      <td>
                        {transac.exchange_account.exchange.name}
                        <sup id="sup_plateforme">API</sup>
                      </td>
                    );
                  })()}
                  <td>{this.getDate(transac.date)}</td>
                  {(() => {
                    if (transac.trade_type == "buy") {
                      return (
                        <td id="achat_field">
                          {transac.fiat_value.toFixed(2)} €
                        </td>
                      );
                    }
                    return (
                      <td id="vente_field">
                        {transac.fiat_value.toFixed(2)} €
                      </td>
                    );
                  })()}
                  {(() => {
                    if (transac.capital_gain !== null) {
                      return <td>{transac.capital_gain.toFixed(2)} €</td>;
                    }
                    return <td></td>;
                  })()}
                </tr>
              ))}
              {this.state.padding_table.map((pad) => (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <br />
        </div>
      );
    }
  }
}
