import React, { Component } from "react";
import "./recap.css";
import Urls from "../urls/urls";
import { Button, Confirm, Message } from "semantic-ui-react";

/**
 * DESCRIPTION : The "Recap" Component display the component which contains a summary of the account.
 * <br/><br/>
 * STATE :<br/>
 * total_investi : contains the amount of invested fund<br/>
 * portefeuille_actuel : contains the value of the wallet<br/>
 * gain_perte : contains the amount of win or loss<br/>
 * roi : contains the return of investment<br/>
 * total_stable : contains the amount (eur, usd and percentage) of stable<br/>
 * total_crypto : contains the amount (eur, usd and percentage) of crypto<br/>
 * rate : contains the eur/usd rate<br/>
 * loading : is used to wait to all data set<br/>
 * last_maj : contains the date of the last update<br/>
 * open : contains the state of the modal(true for open and false for close)
 * <br/><br/>
 * RETURN :<br/>
 * Recap page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Recap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_investi: "N/A",
      portefeuille_actuel: "N/A",
      gain_perte: "N/A",
      roi: "N/A",
      total_stable: ["N/A", "N/A", "N/A"],
      total_crypto: ["N/A", "N/A", "N/A"],
      rate: 1,
      loading: true,
      last_maj: "N/A",
      open: false,
      error: "",
    };
  }

  /**
   * function : open is used to open the modal of confirmation.
   * input : none
   * output : none
   */
  open = () => this.setState({ open: true });

  /**
   * function : close is used to close the modal of confirmation.
   * input : none
   * output : none
   */
  close = () => this.setState({ open: false });

  /**
   * function : ajustRecapTop is used to adjust the top margin of the main div.
   * input : none
   * output : none
   */
  ajustRecapTop = () => {
    if (window.innerWidth > 600) {
      var clientHeight = document.getElementById("header").clientHeight;
      document
        .getElementById("recap")
        .style.setProperty("top", clientHeight + "px");
      document.getElementById("recap").style.setProperty("left", "0px");
    } else {
      var clientHeight =
        document.getElementById("header_smartphone").clientHeight;
      document
        .getElementById("recap_smartphone")
        .style.setProperty("top", clientHeight + "px");
      document
        .getElementById("recap_smartphone")
        .style.setProperty("left", "0px");
    }
  };

  /**
   * function : getEurUsdRate is used to get eur/usd rate.
   * input : none
   * output : none
   */
  getEurUsdRate = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      "https://api.exchangerate.host/convert?from=EUR&to=USD",
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          this.setState({ rate: data.info.rate });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getSummary is used to get all summary information such as avaiable fiat, total balance etc
   * input : none
   * output : none
   */
  getSummary = () => {
    // Function to fetch summary data from the API
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"), // Include authorization token in headers
      },
    };

    // Fetch data from the API endpoint
    fetch(Urls.urls_api + "crypto/summary", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          // Process the received data
          if (data.account_limit === "Account subscription limits reached") {
            // Check if there's an account limit error
            this.setState({ error: "account_limit" });
          }

          // Format the last update time
          var date =
            data.last_update[11] +
            data.last_update[12] +
            "h" +
            data.last_update[14] +
            data.last_update[15];
          this.setState({ last_maj: date });

          // Update state with the received summary data
          this.setState({
            total_investi: data.total_invested.toFixed(0),
            portefeuille_actuel: data.total_value_euro.toFixed(0),
            gain_perte: data.profit.toFixed(2),
            roi: data.roi.toFixed(0),
            total_stable: [
              data.stablecoins_stats.total_value_euro.toFixed(0),
              (
                data.stablecoins_stats.total_value_euro * this.state.rate
              ).toFixed(0),
              data.stablecoins_stats.percentage.toFixed(0),
            ],
            total_crypto: [
              data.crypto_stats.total_value_euro.toFixed(0),
              (data.crypto_stats.total_value_euro * this.state.rate).toFixed(0),
              data.crypto_stats.percentage.toFixed(0),
            ],
          });

          // Update loading state to indicate data is loaded
          this.setState({ loading: false });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : deleteAllData is used to delete all data present on user account
   * input : none
   * output : none
   */
  deleteAllData = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/crypto/user-data/delete", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          window.location.reload();
          this.close();
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to get all summary informations and change the top margin
   */
  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.ajustRecapTop();
    }, 1000);
    this.getEurUsdRate();
    this.getSummary();
    setTimeout(() => {
      this.setState({ loading: false });
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  componentDidCatch() {
    clearInterval(this.intervalId);
  }

  render() {
    if (this.state.loading && window.innerWidth > 600) {
      return (
        <div id="recap">
          <h1>Veuillez patienter</h1>
        </div>
      );
    } else if (this.state.loading && window.innerWidth <= 600) {
      return (
        <div id="recap_smartphone">
          <h1>Veuillez patienter</h1>
        </div>
      );
    } else if (!this.state.loading && window.innerWidth > 600) {
      return (
        <div id="recap">
          {(() => {
            if (this.state.error == "account_limit") {
              return (
                <Message
                  negative
                  id="alert"
                  header="Il y a un probleme"
                  content="Veuillez vérifier votre abonnement"
                />
              );
            }
          })()}
          <div id="maj">
            <h6 id="grey_text">Mise à jour toutes les 5 minutes</h6>
            <h6 id="grey_text">dernière mise à jour : {this.state.last_maj}</h6>
            <Button
              size="mini"
              id="button_deleted_all"
              onClick={() => this.open()}
            >
              Supprimer les données
            </Button>
            <Confirm
              open={this.state.open}
              onCancel={this.close}
              onConfirm={() => this.deleteAllData()}
              content="Etes-vous sur de vouloir supprimer toutes vos données ?"
            />
          </div>
          <div id="recap_total_investi">
            <h4>Total investi</h4>
            <h4 id="number">{this.state.total_investi} €</h4>
          </div>
          <div id="recap_portefeuille_actuel">
            <h4>Portefeuille actuel</h4>
            <h4 id="number">{this.state.portefeuille_actuel} €</h4>
          </div>
          <div id="recap_gains_pertes">
            <h4>Gains/Pertes</h4>
            {(() => {
              if (this.state.gain_perte.toString() >= "N/A") {
                return <h4 id="number">{this.state.gain_perte} €</h4>;
              }
              if (this.state.gain_perte.toString() >= "0") {
                return (
                  <h4 id="number" style={{ color: "green" }}>
                    {this.state.gain_perte} €
                  </h4>
                );
              }
              return (
                <h4 id="number" style={{ color: "red" }}>
                  {this.state.gain_perte} €
                </h4>
              );
            })()}
          </div>
          <div id="recap_roi">
            <h4>ROI</h4>
            {(() => {
              if (this.state.roi.toString() >= "N/A") {
                return <h4 id="number">{this.state.roi} %</h4>;
              }
              if (this.state.roi.toString() >= "0") {
                return (
                  <h4 id="number" style={{ color: "green" }}>
                    {this.state.roi} %
                  </h4>
                );
              }
              return (
                <h4 id="number" style={{ color: "red" }}>
                  {this.state.roi} %
                </h4>
              );
            })()}
          </div>
          <div id="recap_left">
            <div id="recap_total_stable">
              <h4>Total Stable</h4>
              <h4 id="number">{this.state.total_stable[0]} €</h4>
              <h4 id="number">{this.state.total_stable[1]} $</h4>
              <h4 id="number">{this.state.total_stable[2]} %</h4>
            </div>
            <br />
            <div id="recap_total_crypto">
              <h4>Total Crypto</h4>
              <h4 id="number">{this.state.total_crypto[0]} €</h4>
              <h4 id="number">{this.state.total_crypto[1]} $</h4>
              <h4 id="number">{this.state.total_crypto[2]} %</h4>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="recap_smartphone">
          {(() => {
            if (this.state.error == "account_limit") {
              return (
                <Message
                  negative
                  id="alert"
                  header="Il y a un probleme"
                  content="Veuillez vérifier votre abonnement"
                />
              );
            }
          })()}
          <div id="maj">
            <h6 id="grey_text">dernière mise à jour : {this.state.last_maj}</h6>
            <Button id="button_deleted_all" onClick={() => this.open()}>
              Supprimer les données
            </Button>
            <Confirm
              open={this.state.open}
              onCancel={this.close}
              onConfirm={() => this.deleteAllData()}
              content="Etes-vous sur de vouloir supprimer toutes vos données ?"
            />
            <h6 id="grey_text">Mise à jour toutes les 5 minutes</h6>
          </div>
          <div id="recap_right">
            <div id="recap_total_investi">
              <h4>Total investi</h4>
              <h4>{this.state.total_investi} €</h4>
            </div>
            <div id="recap_portefeuille_actuel">
              <h4>Portefeuille</h4>
              <h4>{this.state.portefeuille_actuel} €</h4>
            </div>
            <div id="recap_gains_pertes">
              <h4>Gains/Pertes</h4>
              {(() => {
                if (this.state.gain_perte.toString() >= "N/A") {
                  return <h4>{this.state.gain_perte} €</h4>;
                }
                if (this.state.gain_perte.toString() >= "0") {
                  return (
                    <h4 style={{ color: "green" }}>
                      {this.state.gain_perte} €
                    </h4>
                  );
                }
                return (
                  <h4 style={{ color: "red" }}>{this.state.gain_perte} €</h4>
                );
              })()}
            </div>
            <div id="recap_roi">
              <h4>ROI</h4>
              {(() => {
                if (this.state.roi.toString() >= "N/A") {
                  return <h4>{this.state.roi} %</h4>;
                }
                if (this.state.roi.toString() >= "0") {
                  return <h4 style={{ color: "green" }}>{this.state.roi} %</h4>;
                }
                return <h4 style={{ color: "red" }}>{this.state.roi} %</h4>;
              })()}
            </div>
          </div>
          <div id="recap_total_stable">
            <h4>Total Stable</h4>
            <h4>{this.state.total_stable[0]} €</h4>
            <h4>{this.state.total_stable[1]} €</h4>
            <h4>{this.state.total_stable[2]} %</h4>
          </div>
          <div id="recap_total_crypto">
            <h4>Total Crypto</h4>
            <h4>{this.state.total_crypto[0]} €</h4>
            <h4>{this.state.total_crypto[1]} €</h4>
            <h4>{this.state.total_crypto[2]} %</h4>
          </div>
        </div>
      );
    }
  }
}
