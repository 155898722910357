import React, { Component } from "react";
import Urls from "../urls/urls";
import "./dashboard.css";
import ReactApexChart from "react-apexcharts";
import { Button, Icon, Modal, Input, Form, Checkbox } from "semantic-ui-react";
import Recap from "../recap/recap";
import { Link } from "react-router-dom";
import Wait from "../wait/wait";

/**
 * DESCRIPTION : The "Dashboard" Component display dashboard page which contains a good summary on crypto stable and plateform.
 * in Dashboard there are many charts which show useful information
 * <br/><br/>
 * STATE :<br/>
 * rate : contains the eur/usd rate<br/>
 * stable_table : contains all json which will be displayed in stable table<br/>
 * crypto_table : contains all json which will be displayed in crypto table<br/>
 * plateforme_table : contains all json which will be displayed in plateform table<br/>
 * taux_stable_crypto : contains the stable/crypto rate<br/>
 * graph_crypto : contains the information and settings of crypto charts<br/>
 * graph_stable_crypto : contains the information and settings of stable/crypto charts<br/>
 * graph_plateforme : contains the information and settings of plateform charts<br/>
 * loading : is used to wait to all data set<br/><br/>
 * RETURN :<br/>
 * Dashboard page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: 1,
      stable_table: [],
      crypto_table: [],
      plateforme_table: [],
      price_stable: [],
      price_crypto: [],
      open: false,
      sort_type: "",
      taux_stable_crypto: {
        stable: 51,
        crypto: 49,
      },
      graph_crypto: {
        series: [100],
        options: {
          legend: {
            position: "bottom",
            height: 21,
            colors: "#EEEEEE",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "45%",
              },
            },
          },
          chart: {
            width: 50,
          },
          theme: {
            palette: "palette2",
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "auto",
            },
          },
          labels: ["N/A"],
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  width: 180,
                },
                legend: {
                  horizontalAlign: "center",
                  width: 150,
                },
                label: {
                  style: {
                    fontSize: "5px",
                  },
                },
              },
            },
          ],
        },
      },
      graph_stable_crypto: {
        series: [100],
        options: {
          legend: {
            position: "bottom",
            height: 21,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "55%",
              },
            },
          },
          chart: {
            width: 50,
          },
          theme: {
            palette: "palette2",
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "auto",
            },
          },
          labels: ["N/A"],
          responsive: [
            {
              breakpoint: 600,
              chart: {
                width: "100%",
              },
              options: {
                chart: {
                  width: 180,
                },
                legend: {
                  horizontalAlign: "center",
                  width: 150,
                },
                label: {
                  style: {
                    fontSize: "5px",
                  },
                },
              },
            },
          ],
        },
      },
      graph_plateforme: {
        series: [100],
        options: {
          legend: {
            position: "bottom",
            height: 21,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "55%",
              },
            },
          },
          chart: {
            width: 50,
          },
          theme: {
            palette: "palette2",
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "auto",
            },
          },
          labels: ["N/A"],
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  width: 185,
                },
                legend: {
                  position: "bottom",
                },
                label: {
                  style: {
                    fontSize: "5px",
                  },
                },
              },
            },
          ],
        },
      },
      loading: true,
      padding_table_right: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      padding_table_left: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    };
  }

  /**
   * function : getEurUsdRate is used to get eur/usd rate.
   * input : none
   * output : none
   */
  getEurUsdRate = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      "https://api.exchangerate.host/convert?from=EUR&to=USD",
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          this.setState({ rate: data.info.rate });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : updateGraphCrypto is used to update the crypto charts.
   * input : _series : contains all figure needed
   *         _labels : contains all labels
   * output : none
   */
  updateGraphCrypto = (_series, _labels) => {
    this.setState({
      graph_crypto: {
        series: _series,
        options: {
          legend: {
            position: "bottom",
            height: 21,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "55%",
              },
            },
          },
          theme: {
            palette: "palette1",
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "auto",
            },
          },
          labels: _labels,
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  width: 185,
                },
                legend: {
                  position: "bottom",
                  horizontalAlign: "center",
                  width: 150,
                },
                label: {
                  style: {
                    fontSize: "5px",
                  },
                },
              },
            },
          ],
        },
      },
    });
  };

  /**
   * function : updateGraphStableCrypto is used to update the stable/crypto charts.
   * input : _series : contains all figure needed
   *         _labels : contains all labels
   * output : none
   */
  updateGraphStableCrypto = (_series, _labels) => {
    this.setState({
      graph_stable_crypto: {
        series: _series,
        options: {
          legend: {
            position: "bottom",
            height: 21,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "55%",
              },
            },
          },
          theme: {
            palette: "palette8",
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "auto",
            },
          },
          labels: _labels,
          responsive: [
            {
              breakpoint: 600,
              chart: {
                width: "100%",
              },
              options: {
                chart: {
                  width: 185,
                },
                legend: {
                  position: "bottom",
                  horizontalAlign: "center",
                  width: 150,
                },
                label: {
                  style: {
                    fontSize: "5px",
                  },
                },
              },
            },
          ],
        },
      },
    });
  };

  /**
   * function : updateGraphPlateform is used to update the plateform charts.
   * input : _series : contains all figure needed
   *         _labels : contains all labels
   * output : none
   */
  updateGraphPlateform = (_series, _labels) => {
    this.setState({
      graph_plateforme: {
        series: _series,
        options: {
          legend: {
            position: "bottom",
            height: 21,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "55%",
              },
            },
          },
          theme: {
            palette: "palette3",
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "auto",
            },
          },
          labels: _labels,
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  width: 300,
                },
                legend: {
                  position: "bottom",
                },
                label: {
                  style: {
                    fontSize: "5px",
                  },
                },
              },
            },
          ],
        },
      },
    });
  };

  /**
   * function : getSummary is used to set all data in table and graph.
   * input : none
   * output : none
   */
  getSummary = () => {
    // Set up the request options including headers
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
  
    // Send a GET request to the specified API endpoint
    fetch(Urls.urls_api + "crypto/dashboard", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          // Initialize variables
          var series = [];
          var labels = [];
          var total_stable = 0;
          var total_crypto = 0;
  
          // Iterate over the data to process total balances
          for (var key in data.total_balances) {
            if (data.total_balances[key].stablecoin) {
              // Process stablecoin balances
              this.state.price_stable.push(
                data.total_balances[key].price.toFixed(2)
              );
              total_stable = total_stable + data.total_balances[key].value;
              this.state.stable_table.push({
                name: key,
                price: data.total_balances[key].price,
                amount: (
                  data.total_balances[key].value /
                  data.total_balances[key].price
                ).toFixed(2),
                eur_price: data.total_balances[key].value.toFixed(2),
                usd_price: (
                  data.total_balances[key].value.toFixed(2) *
                  this.state.rate.toFixed(2)
                ).toFixed(2),
                percent: data.total_balances[key].percentage,
              });
            } else {
              // Process cryptocurrency balances
              this.state.price_crypto.push(
                data.total_balances[key].price.toFixed(2)
              );
              total_crypto = total_crypto + data.total_balances[key].value;
              this.state.crypto_table.push({
                name: key,
                price: data.total_balances[key].price,
                amount: (
                  data.total_balances[key].value /
                  data.total_balances[key].price
                ).toFixed(2),
                eur_price: data.total_balances[key].value.toFixed(2),
                usd_price: (
                  data.total_balances[key].value.toFixed(2) *
                  this.state.rate.toFixed(2)
                ).toFixed(2),
                percent: data.total_balances[key].percentage,
              });
            }
            series.push(data.total_balances[key].value);
            labels.push(key);
          }
  
          // Update the state with stable/crypto ratio
          this.setState({
            taux_stable_crypto: {
              stable: (
                (total_stable / (total_stable + total_crypto)) *
                100
              ).toFixed(2),
              crypto: (
                (total_crypto / (total_stable + total_crypto)) *
                100
              ).toFixed(2),
            },
          });
  
          // Process exchange and custom accounts
          var series_plateform = [];
          var labels_plateform = [];
          for (var key in data.total_accounts.exchanges_accounts) {
            // Process exchange accounts
            this.state.plateforme_table.push({
              name: data.total_accounts.exchanges_accounts[key].name,
              eur_value:
                data.total_accounts.exchanges_accounts[
                  key
                ].total_value_euro.toFixed(2),
              dol_value: (
                data.total_accounts.exchanges_accounts[key].total_value_euro *
                this.state.rate.toFixed(2)
              ).toFixed(2),
              percent: data.total_accounts.exchanges_accounts[key].percentage,
            });
            series_plateform.push(
              data.total_accounts.exchanges_accounts[key].total_value_euro
            );
            labels_plateform.push(
              data.total_accounts.exchanges_accounts[key].name
            );
          }
          for (var key in data.total_accounts.custom_accounts) {
            // Process custom accounts
            this.state.plateforme_table.push({
              name: data.total_accounts.custom_accounts[key].name,
              eur_value:
                data.total_accounts.custom_accounts[
                  key
                ].total_value_euro.toFixed(2),
              dol_value: (
                data.total_accounts.custom_accounts[key].total_value_euro *
                this.state.rate
              ).toFixed(2),
              percent: data.total_accounts.custom_accounts[key].percentage,
            });
            series_plateform.push(
              data.total_accounts.custom_accounts[key].total_value_euro
            );
            labels_plateform.push(
              data.total_accounts.custom_accounts[key].name
            );
          }
  
          // Update graphs and tables
          this.updateGraphCrypto(series, labels);
          this.updateGraphPlateform(series_plateform, labels_plateform);
          this.updateGraphStableCrypto(
            [total_stable, total_crypto],
            ["Stable", "Crypto"]
          );
          this.state.crypto_table.map((api) => {
            this.state.padding_table_left.shift();
          });
          this.state.stable_table.map((api) => {
            this.state.padding_table_left.shift();
          });
  
          // Update the loading state
          this.setState({ loading: false });
        })
      )
      .catch((err) => console.log(err));
  };
  

  /**
   * function : sortCrypto is used to sort the left table according to name or value.
   * input :
   *  _sort_type : the type of sorting wanted
   * output : none
   */
  sortCrypto = (_sort_type) => {
    var new_price_crypto = [];
    var new_price_stable = [];
    if (_sort_type === "value") {
      this.state.crypto_table.sort(function (a, b) {
        return parseFloat(b.price.toFixed(2)) - parseFloat(a.price.toFixed(2));
      });
      this.state.stable_table.sort(function (a, b) {
        return parseFloat(b.price.toFixed(2)) - parseFloat(a.price.toFixed(2));
      });
    }
    if (_sort_type === "name") {
      this.state.crypto_table.sort((a, b) => a.name.localeCompare(b.name));
      this.state.stable_table.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.state.crypto_table.map((crypto) =>
      new_price_crypto.push(crypto.price.toFixed(2))
    );
    this.state.stable_table.map((stable) =>
      new_price_stable.push(stable.price.toFixed(2))
    );
    this.setState({ price_crypto: new_price_crypto });
    this.setState({ price_stable: new_price_stable });
    this.setState({ open: false });
  };

  /**
   * function : changeMarginDiv is used to adjust the top margin of the main div.
   * input : none
   * output : none
   */
  changeMarginDiv = () => {
    if (window.innerWidth > 600) {
      var headerHeight = document.getElementById("header").clientHeight;
      var recapHeight = document.getElementById("recap").clientHeight;
      document
        .getElementById("dashboard")
        .style.setProperty("top", headerHeight + recapHeight + 10 + "px");
    } else {
      var headerHeight =
        document.getElementById("header_smartphone").clientHeight;
      var recapHeight =
        document.getElementById("recap_smartphone").clientHeight;
      document
        .getElementById("dashboard_tel")
        .style.setProperty("top", headerHeight + recapHeight + 10 + "px");
    }
  };

  changePaddingHeight = () => {
    var nb_crypto = this.state.crypto_table.length;
    var nb_stable = this.state.stable_table.length;
    var nb_plateform = this.state.plateforme_table.length;
    if (nb_stable === 0) {
      const thead22 = document.querySelector("thead:nth-child(3) > th");
      thead22.style.borderTopLeftRadius = "15px";
      thead22.style.borderTopRightRadius = "15px";
    } else {
      const thead22 = document.querySelector("thead:nth-child(3) > th");
      thead22.style.borderTopLeftRadius = "0px";
      thead22.style.borderTopRightRadius = "0px";
    }
  };

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to get eur/usd rate, and information of table and charts
   */
  componentDidMount() {
    if (
      window.sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server;
    } else {
      this.getEurUsdRate();
      this.getSummary();
      this.intervalId = setInterval(() => {
        this.changePaddingHeight();
        this.changeMarginDiv();
      }, 1000);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 15000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    if (this.state.loading) {
      return (
        <div id="wait_container">
          <Recap />
          <Wait />
        </div>
      );
    }
    if (window.innerWidth > 600) {
      return (
        <div className="fade-in" id="dashboard">
          <Recap />
          <br />
          <div id="graph_div">
            <div id="graph_crypto">
              <h3>Crypto</h3>
              <ReactApexChart
                options={this.state.graph_crypto.options}
                series={this.state.graph_crypto.series}
                type="donut"
              />
            </div>
            <div id="graph_stable_crypto">
              <h3>Stable/Crypto</h3>
              <ReactApexChart
                options={this.state.graph_stable_crypto.options}
                series={this.state.graph_stable_crypto.series}
                type="donut"
              />
            </div>
            <div id="graph_plateforme">
              <h3>Plateformes</h3>
              <ReactApexChart
                options={this.state.graph_plateforme.options}
                series={this.state.graph_plateforme.series}
                type="donut"
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <div id="dashboard_table">
            <div id="right_table_div">
              <table id="table_prix">
                <thead>
                  <th>
                    <Modal
                      id="modal_sort"
                      onClose={() => this.setState({ open: false })}
                      onOpen={() => this.setState({ open: true })}
                      open={this.state.open}
                      trigger={<Button id="modal_button">Tri</Button>}
                    >
                      <div id="modal_modal_sort">
                        <h2>trier par : </h2>
                        <br />
                        <div id="modal_sort_div_button">
                          <Button
                            id="modal_sort_button"
                            onClick={() => this.sortCrypto("name")}
                          >
                            Ordre alphabetique
                          </Button>
                          <Button
                            id="modal_sort_button"
                            onClick={() => this.sortCrypto("value")}
                          >
                            Ordre de valeur
                          </Button>
                        </div>
                      </div>
                    </Modal>
                  </th>
                </thead>
                <tbody>
                  {this.state.price_stable.map((stable) => (
                    <tr>
                      <td>{stable}$</td>
                    </tr>
                  ))}
                </tbody>
                <thead>
                  <th>&nbsp;</th>
                </thead>
                <tbody>
                  {this.state.price_crypto.map((crypto) => (
                    <tr>
                      <td>{crypto}$</td>
                    </tr>
                  ))}
                  {this.state.padding_table_left.map((pad) => (
                    <tr>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table id="table_dashboard_crypto">
                <thead id="thead1">
                  <th>Stable</th>
                  <th>Quantité</th>
                  <th>Valeur $</th>
                  <th>Valeur €</th>
                  <th>%</th>
                </thead>
                <tbody id="tbody11">
                  {this.state.stable_table.map((stable) => (
                    <tr>
                      <td>{stable.name}</td>
                      <td>{stable.amount}</td>
                      <td>{stable.usd_price}$</td>
                      <td>{stable.eur_price}€</td>
                      <td>{stable.percent}%</td>
                    </tr>
                  ))}
                </tbody>
                <thead id="thead2">
                  <th>Crypto</th>
                  <th>Quantité</th>
                  <th>Valeur $</th>
                  <th>Valeur €</th>
                  <th>%</th>
                </thead>
                <tbody id="tbody11">
                  {this.state.crypto_table.map((crypto) => (
                    <tr>
                      <td>{crypto.name}</td>
                      <td>{crypto.amount}</td>
                      <td>{crypto.usd_price}$</td>
                      <td>{crypto.eur_price}€</td>
                      <td>{crypto.percent}%</td>
                    </tr>
                  ))}
                  {this.state.padding_table_left.map((pad) => (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <table id="table_dashboard_plateforme">
              <thead id="thead1">
                <th>Plateforme</th>
                <th>Valeur $</th>
                <th>Valeur €</th>
                <th>%</th>
              </thead>
              <tbody id="tbody">
                {(() => {
                  if (this.state.plateforme_table.length === 0) {
                    return (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  }
                })()}
                {this.state.plateforme_table.map((plateform) => (
                  <tr>
                    <td>{plateform.name}</td>
                    <td>{plateform.dol_value}$</td>
                    <td>{plateform.eur_value}€</td>
                    <td>{plateform.percent}%</td>
                  </tr>
                ))}
                {this.state.padding_table_right.map((pad) => (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="fade-in" id="dashboard_tel">
          <Recap />
          <br />
          <div id="div_graph">
            <div id="graph_crypto_tel" style={{ MarginRight: 5 }}>
              <ReactApexChart
                id="test"
                options={this.state.graph_crypto.options}
                series={this.state.graph_crypto.series}
                type="donut"
              />
            </div>
            <div id="graph_crypto_tel">
              <ReactApexChart
                id="test"
                options={this.state.graph_stable_crypto.options}
                series={this.state.graph_stable_crypto.series}
                type="donut"
              />
            </div>
          </div>
          <br />
          <div id="right_table_div">
            <table id="table_prix">
              <thead>
                <th>
                  <Modal
                    id="modal_sort"
                    onClose={() => this.setState({ open: false })}
                    onOpen={() => this.setState({ open: true })}
                    open={this.state.open}
                    trigger={<Button id="modal_button">Tri</Button>}
                  >
                    <div id="modal_modal_sort">
                      <h2>trier par : </h2>
                      <br />
                      <div id="modal_sort_div_button">
                        <Button
                          id="modal_sort_button"
                          onClick={() => this.sortCrypto("name")}
                        >
                          Ordre alphabetique
                        </Button>
                        <Button
                          id="modal_sort_button"
                          onClick={() => this.sortCrypto("value")}
                        >
                          Ordre de valeur
                        </Button>
                      </div>
                    </div>
                  </Modal>
                </th>
              </thead>
              <tbody>
                {this.state.price_stable.map((stable) => (
                  <tr>
                    <td>{stable}$</td>
                  </tr>
                ))}
              </tbody>
              <thead>
                <th>&nbsp;</th>
              </thead>
              <tbody>
                {this.state.price_crypto.map((crypto) => (
                  <tr>
                    <td>{crypto}$</td>
                  </tr>
                ))}
                {this.state.padding_table_left.map((pad) => (
                  <tr>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table id="table_dashboard_crypto">
              <thead id="thead1">
                <th>Stable</th>
                <th>Quantité</th>
                <th>Valeur $</th>
                <th>Valeur €</th>
                <th>%</th>
              </thead>
              <tbody id="tbody11">
                {this.state.stable_table.map((stable) => (
                  <tr>
                    <td>{stable.name}</td>
                    <td>{stable.amount}</td>
                    <td>{stable.usd_price}$</td>
                    <td>{stable.eur_price}€</td>
                    <td>{stable.percent}%</td>
                  </tr>
                ))}
              </tbody>
              <thead id="thead2">
                <th>Crypto</th>
                <th>Quantité</th>
                <th>Valeur $</th>
                <th>Valeur €</th>
                <th>%</th>
              </thead>
              <tbody id="tbody11">
                {this.state.crypto_table.map((crypto) => (
                  <tr>
                    <td>{crypto.name}</td>
                    <td>{crypto.amount}</td>
                    <td>{crypto.usd_price}$</td>
                    <td>{crypto.eur_price}€</td>
                    <td>{crypto.percent}%</td>
                  </tr>
                ))}
                {this.state.padding_table_left.map((pad) => (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      );
    }
  }
}
