import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import "./wait.css";

/**
 * DESCRIPTION : The class "Wait" is a page used to wait until the connexion is done<br/><br/>
 * RETURN : <br/>
 * The Wait page
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Wait extends Component {
  render() {
    return (
      <div id="wait">
        <Loader active size="large" inline="centered" />
      </div>
    );
  }
}
