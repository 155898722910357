import React, { Component } from "react";
import { Loader } from "semantic-ui-react";
import "./load.css";
import Urls from "../urls/urls";

/**
 * DESCRIPTION : The class "Load" is a page used to wait until the connexion is done<br/><br/>
 * RETURN : <br/>
 * The loading page
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Load extends Component {
  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to verify if the user is connected and will remove the password of the local storage
   */
  componentDidMount() {
    if (
      window.sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server;
    } else {
      localStorage.removeItem("pwd");
      window.location.href = Urls.urls_server + "dashboard";
    }
  }

  render() {
    return (
      <div id="load">
        <h1>Veuillez patientez</h1>
        <Loader active inline="centered" />
      </div>
    );
  }
}
