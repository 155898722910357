import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Inscription from "./components/inscription/inscription";
import Connexion from "./components/connexion/connexion";
import Footer from "./components/footer/footer";
import Accueil from "./components/accueil/accueil";
import Header from "./components/header/header";
import Tarif from "./components/tarif/tarif";
import Dashboard from "./components/dashboard/dashboard";
import Settings from "./components/settings/settings";
import Loader from "./components/load/load";
import Mail from "./components/mail_alert/mail";
import Forgotpwd from "./components/forgotpwd/forgotpwd";
import Dfa from "./components/Dfa/Dfa";
import Resetpwd from "./components/resetpwd/resetpwd";
import Changeinfo from "./components/changeinfo/changeinfo";
import Change2fa from "./components/change2fa/change2fa";
import Transaction from "./components/transaction/transaction";
import Saisie from "./components/saisie/saisie";
import Apiconnexion from "./components/apiconnexion/apiconnexion";
import Success from "./components/success/success";
import Cancelled from "./components/cancelled/cancelled";
import Mentions from "./components/mentions/mentions";
import "./App.css";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({ windowWidth: window.innerWidth });
    }, 500);
  }

  componentWillUnmount(){
    
  }

  render() {
    if (this.state.windowWidth < 600) {
      return (
        <div id="app">
          <Header />
          <br />
          <br />
          <br />
          <Routes>
            <Route path="/" element={<Accueil />} />
            <Route path="/tarif" element={<Tarif />} />
            <Route path="/connexion" element={<Connexion />} />
            <Route path="/inscription" element={<Inscription />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/load" element={<Loader />} />
            <Route path="/2fa" element={<Dfa />} />
            <Route path="/forgotpwd" element={<Forgotpwd />} />
            <Route path="/mailalert" element={<Mail />} />
            <Route path="/resetpwd" element={<Resetpwd />} />
            <Route path="/changeinfo" element={<Changeinfo />} />
            <Route path="/change2fa" element={<Change2fa />} />
            <Route path="/saisie" element={<Saisie />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/apiconnexion" element={<Apiconnexion />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancelled" element={<Cancelled />} />
            <Route path="/mentions" element={<Mentions />} />
            <Route path="*" element={<Accueil />} />
          </Routes>
        </div>
      );
    } else {
      return (
        <div id="app">
          <Header />
          <Routes>
            <Route path="/" element={<Accueil />} />
            <Route path="/tarif" element={<Tarif />} />
            <Route path="/connexion" element={<Connexion />} />
            <Route path="/inscription" element={<Inscription />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/load" element={<Loader />} />
            <Route path="/2fa" element={<Dfa />} />
            <Route path="/forgotpwd" element={<Forgotpwd />} />
            <Route path="/mailalert" element={<Mail />} />
            <Route path="/resetpwd" element={<Resetpwd />} />
            <Route path="/changeinfo" element={<Changeinfo />} />
            <Route path="/change2fa" element={<Change2fa />} />
            <Route path="/saisie" element={<Saisie />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/apiconnexion" element={<Apiconnexion />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancelled" element={<Cancelled />} />
            <Route path="/mentions" element={<Mentions />} />
            <Route path="*" element={<Accueil />} />
          </Routes>
          <Footer/>
        </div>
      );
    }
  }
}
