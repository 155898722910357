import React, { Component } from "react";
import Recap from "../recap/recap";
import "./apiconnexion.css";
import { Button, Form, Input, Icon } from "semantic-ui-react";
import Urls from "../urls/urls";
import Wait from "../wait/wait";

/**
 * DESCRIPTION : The "Apiconnexion" Component display the page where user can connect the website and the different plateform.
 * <br/><br/>
 * STATE :<br/>
 * liste_plateforme : contains the list of the plateform connectable<br/>
 * list_api_key : contains the list of connected plateform and key<br/>
 * error : contains the message in case of error<br/>
 * limit : contains the limitation on addable plateform<br/>
 * loading : is used to wait to all data set<br/><br/>
 * RETURN :<br/>
 * Apiconnexion page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Apiconnexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liste_plateforme: [],
      list_api_key: [],
      error: "",
      limit: {},
      message: "",
      padding_table: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      loading: true,
    };
    this.handleChangePlateform = this.handleChangePlateform.bind(this);
  }

  /**
   * function : handleChangePlateform is used to change plateform input
   * input : none
   * output : none
   */
  handleChangePlateform(event) {
    this.setState({ chosen_plateform: event.target.value });
  }

  /**
   * function : changeMarginDiv is used to adjust the top margin of the main div.
   * input : none
   * output : none
   */
  changeMarginDiv = () => {
    if (window.innerWidth > 600) {
      var headerHeight = document.getElementById("header").clientHeight;
      var recapHeight = document.getElementById("recap").clientHeight;
      document
        .getElementById("apiconnexion")
        .style.setProperty("top", headerHeight + recapHeight + 10 + "px");
    } else {
      var headerHeight =
        document.getElementById("header_smartphone").clientHeight;
      var recapHeight =
        document.getElementById("recap_smartphone").clientHeight;
      document
        .getElementById("apiconnexion_tel")
        .style.setProperty("top", headerHeight + recapHeight + 10 + "px");
    }
  };

  /**
   * function : getAllExchange is used to get all exchange possible.
   * input : none
   * output : none
   */
  getAllExchange = () => {
    const requestOptions = {
      method: "GET",
    };
    fetch(Urls.urls_api + "api/exchange/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ liste_plateforme: data });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : getAccountExchange is used to get all exchange connected using API
   * input : none
   * output : none
   */
  getAccountExchange = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "api/exchange-account/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ list_api_key: data });
          this.setState({ loading: false });
          data.map((api) => {
            this.state.padding_table.slice(0, -1);
          });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : deleteAccountExchange is used to detele a exchange connection
   * input : none
   * output : none
   */
  deleteAccountExchange = (_list) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(
      Urls.urls_api + "api/exchange-account/" + _list.id + "/",
      requestOptions
    )
      .then((res) =>
        res.json().then((data) => {
          this.getAccountExchange();
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * function : addExchange is used to connect an exchange
   * input : none
   * output : none
   */
  addExchange = () => {
    // Get values from input fields
    var plateform = document.getElementById("list_plateform").value;
    var apikey = document.getElementById("api_key_field").value;
    var secretkey = document.getElementById("secret_key_field").value;
    var plateform_id;
    var validation = true;
  
    // Check if the exchange and API key already exist in the list
    this.state.list_api_key.map((api) => {
      if (plateform === api.exchange.name && apikey === api.api_key) {
        validation = false;
      }
    });
  
    // Validate the input fields
    if (plateform === "" && apikey === "" && secretkey === "") {
      this.setState({ error: "Veuillez compléter le formulaire" });
    } else if (validation === false) {
      this.setState({ error: "Vous avez déjà ajouté ce compte" });
      return;
    } else {
      // Get the platform ID
      this.state.liste_plateforme.map((plat) => {
        if (plat.name === plateform) {
          plateform_id = plat.id;
        }
      });
  
      // Check if the platform has already been added
      this.state.list_api_key.map((plat) => {
        if (plat.name === plateform) {
          this.setState({ error: "La plateforme a déjà été ajoutée" });
          return;
        }
      });
  
      // Check if the limit of exchange accounts has been reached
      if (
        this.state.limit.exchange_accounts_limit >=
        this.state.list_api_key.length
      ) {
        this.setState({
          error:
            "Vous avez atteint la limite pour ce compte, veuillez vérifier votre abonnement.",
        });
        return;
      }
  
      // Prepare the request options for API call
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + window.sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          api_secret: secretkey,
          api_key: apikey,
          user: window.sessionStorage.getItem("uid"),
          exchange_id: plateform_id,
        }),
      };
  
      // Make the API call to add the exchange account
      fetch(Urls.urls_api + "api/exchange-account/", requestOptions)
        .then((res) =>
          res.json().then((data) => {
            if (data.message === "Invalid API keys") {
              this.setState({ error: "Clé API invalide. Veuillez réessayer." });
            } else {
              this.setState({
                message:
                  'Connexion réussite, veuillez entrer les transactions dans l\'onglet "Transaction"',
              });
              this.getAccountExchange();
            }
          })
        )
        .catch((err) => console.log(err));
    }
  };
  

  /**
   * function : getRetriction is used to get the account limiation using API
   * input : none
   * output : none
   */
  getRetriction = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + window.sessionStorage.getItem("token"),
      },
    };
    fetch(Urls.urls_api + "user-subscription-limits", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({ limit: data });
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to get all plateform and connected plateform
   */
  componentDidMount() {
    if (
      window.sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server;
    } else {
      this.getAllExchange();
      this.getRetriction();
      setTimeout(() => {
        this.getAccountExchange();
      }, 500);
      this.intervalId = setInterval(() => {
        this.changeMarginDiv();
      }, 1000);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 15000);
    }
  }

  /**
   * this function will be execute as soon as the component will be removed.
   * it is used to get off the interval
   */
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    if (this.state.loading) {
      return (
        <div id="wait_container">
          <Recap />
          <Wait />
        </div>
      );
    }
    if (window.innerWidth > 600) {
      return (
        <div className="fade-in" id="apiconnexion">
          <Recap />
          <h1 id="titre">Connexion de plateforme via API</h1>
          <h3>Entrer votre plateforme et votre clé</h3>
          <h3 id="text_error">{this.state.error}</h3>
          <h3 id="text_message">{this.state.message}</h3>
          <Form id="form">
            <Form.Field id="field">
              <label id="label">Plateforme</label>
              <Input
                id="list_plateform"
                list="plateforme"
                onChange={this.handleChangePlateform}
                placeholder="Choisir la plateforme"
              />
              <datalist id="plateforme">
                {this.state.liste_plateforme.map((plateform) => (
                  <option value={plateform.name}>{plateform.name}</option>
                ))}
              </datalist>
            </Form.Field>
            <Form.Field id="field">
              <label id="label">Entrer la clé API</label>
              <input id="api_key_field" placeholder="Clé API" />
            </Form.Field>
            <Form.Field id="field">
              <label id="label">Entrer la clé secrète</label>
              <input id="secret_key_field" placeholder="Clé secrète" />
            </Form.Field>
            <Button onClick={this.addExchange} id="main_button" animated>
              <Button.Content visible>Ajouter une plateforme</Button.Content>
              <Button.Content hidden>
                <Icon name="arrow right" />
              </Button.Content>
            </Button>
          </Form>
          <hr />
          <br />
          <table id="tableau_plateform">
            <thead>
              <th>
                <h3>Plateforme</h3>
              </th>
              <th>
                <h3>Clé API</h3>
              </th>
              <th>
                <h3>Supprimer</h3>
              </th>
            </thead>
            <tbody>
              {this.state.list_api_key.map((list_item) => (
                <tr>
                  <td>{list_item.exchange.name}</td>
                  <td>{list_item.api_key}</td>
                  <td>
                    <Button
                      id="button_delete_plateform"
                      onClick={() => this.deleteAccountExchange(list_item)}
                      icon
                    >
                      <h5>X</h5>
                    </Button>
                  </td>
                </tr>
              ))}
              {this.state.padding_table.map((pad) => (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div id="apiconnexion_tel">
          <Recap />
          <h1 id="titre">Connexion de plateforme via API</h1>
          <h3>Entrer votre plateforme et votre clé</h3>
          <h3 id="text_error">{this.state.error}</h3>
          <h3 id="text_message">{this.state.message}</h3>
          <Form id="form">
            <Form.Field id="field">
              <label id="label">Plateforme</label>
              <Input
                id="list_plateform"
                list="plateforme"
                onChange={this.handleChangePlateform}
                placeholder="Choisir la plateforme"
              />
              <datalist id="plateforme">
                {this.state.liste_plateforme.map((plateform) => (
                  <option value={plateform.name}>{plateform.name}</option>
                ))}
              </datalist>
            </Form.Field>
            <Form.Field id="field">
              <label id="label">Entrer la clé API</label>
              <input id="api_key_field" placeholder="Clé API" />
            </Form.Field>
            <Form.Field id="field">
              <label id="label">Entrer la clé secrète</label>
              <input id="secret_key_field" placeholder="Clé secrète" />
            </Form.Field>
            <Button onClick={this.addExchange} id="main_button" animated>
              <Button.Content visible>Ajouter une plateforme</Button.Content>
              <Button.Content hidden>
                <Icon name="arrow right" />
              </Button.Content>
            </Button>
          </Form>
          <hr />
          <br />
          <table id="tableau_plateform_tel">
            <thead>
              <th>
                <h3>Plateforme</h3>
              </th>
              <th>
                <h3>Clé API</h3>
              </th>
              <th>
                <h3>Supprimer</h3>
              </th>
            </thead>
            <tbody>
              {this.state.list_api_key.map((list_item) => (
                <tr>
                  <td>{list_item.exchange.name}</td>
                  <td id="td_api_key">{list_item.api_key}</td>
                  <td>
                    <Button
                      size="mini"
                      id="button_delete"
                      onClick={() => this.deleteAccountExchange(list_item)}
                      icon
                    >
                      <Icon name="close" />
                    </Button>
                  </td>
                </tr>
              ))}
              {this.state.padding_table.map((pad) => (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
}
