/**
 * DESCRIPTION : Urls contains the different url used for api in this project<br/><br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
const Urls = {
  urls_api: "https://cryptorganiz-backend-dev-docker.onrender.com/",
  //urls_api:"http://127.0.0.1:8000/",
  urls_server: "http://localhost:3000/",
  //urls_server: "https://cryptorganiz-front-end.onrender.com/",
};
export default Urls;
