import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import Urls from "../urls/urls";
import "./change2fa.css";

/**
 * DESCRIPTION : The "Change2fa" Component will display the page where the user
 * will enter his code to change 2fa state<br/><br/>
 * STATE :<br/>
 * state_2fa : contains the actual state of the 2fa<br/>
 * code : contains the code field<br/>
 * error : contains the error which will be displayed<br/><br/>
 * RETURN :<br/>
 * login page<br/>
 * @version 2.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Change2fa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_2fa: "true",
      code: "",
      error: "",
      confirm: "",
    };
    this.handleChangeCode = this.handleChangeCode.bind(this);
  }

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to verify if the user is connected and get the 2fa state
   */
  componentDidMount() {
    if (window.sessionStorage.getItem("photo") !== null) {
      window.location.href = Urls.urls_server + "dashboard";
    }
    if (
      window.sessionStorage.getItem("token") === null ||
      window.sessionStorage.getItem("uid") === null
    ) {
      window.location.href = Urls.urls_server;
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      const state = queryParams.get("2fa_state");
      this.setState({ state_2fa: state });
    }
  }

  /**
   * function : handleChangeCode is used to change code state
   * input : field event
   * output : none
   */
  handleChangeCode(event) {
    this.setState({ code: event.target.value });
  }

  /**
   * function : handleSubmit will verify if the code isn't empty and
   * then change the 2fa state
   * input : none
   * output : none
   */
  handleSubmit = () => {
    // Check if the code is empty
    if (this.state.code === "") {
      this.setState({ error: "Veuillez entrer le code" });
      return;
    }
  
    // Handle the case when 2FA is enabled
    if (this.state.state_2fa === "true") {
      var requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + window.sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          enabled: false,
          otp_token: this.state.code,
        }),
      };
    }
  
    // Handle the case when 2FA is disabled
    if (this.state.state_2fa === "false") {
      var requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + window.sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          enabled: true,
          otp_token: this.state.code,
        }),
      };
    }
  
    // Send the API request to toggle 2FA
    fetch(Urls.urls_api + "toggle-2fa/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.two_factor_auth_enabled === true) {
            // 2FA was successfully enabled
            this.setState({
              confirm: "Votre double authentification a bien été activé",
            });
            setTimeout(
              () => (window.location.href = Urls.urls_server + "dashboard"),
              2000
            );
          } else if (data.two_factor_auth_enabled === false) {
            // 2FA was successfully disabled
            this.setState({
              confirm: "Votre double authentification a bien été désactivé",
            });
            setTimeout(
              () => (window.location.href = Urls.urls_server + "dashboard"),
              2000
            );
          } else {
            // The entered code was incorrect
            this.setState({ error: "Veuillez entrer le bon code" });
          }
        })
      )
      .catch((err) => console.log(err));
  };
  
  render() {
    if (this.state.state_2fa === "true") {
      return (
        <div id="change2fa">
          <h3 id="green_label">{this.state.confirm}</h3>
          <h1 id="titre">Désactiver votre double authentification</h1>
          <h3 id="text">
            Un mail vous a été envoyé, veuillez entrer le code indiqué
          </h3>
          <h3 id="text_error">{this.state.error}</h3>
          <Form id="form">
            <Form.Field id="field">
              <label id="label">Code</label>
              <input
                type="text"
                placeholder="Code"
                value={this.state.code}
                onChange={this.handleChangeCode}
              />
            </Form.Field>
            <br />
            <Button id="main_button" type="submit" onClick={this.handleSubmit}>
              Changer la 2fa
            </Button>
          </Form>
        </div>
      );
    } else {
      return (
        <div id="change2fa">
          <h3 id="green_label">{this.state.confirm}</h3>
          <h1 id="titre">Activer votre double authentification</h1>
          <h3 id="text">
            Un mail vous a été envoyé, veuillez entrer le code indiqué
          </h3>
          <h3 id="text_error">{this.state.error}</h3>
          <Form id="form">
            <Form.Field id="field">
              <label id="label">Code</label>
              <input
                type="text"
                placeholder="Code"
                value={this.state.code}
                onChange={this.handleChangeCode}
              />
            </Form.Field>
            <br />
            <Button id="main_button" type="submit" onClick={this.handleSubmit}>
              Changer la 2fa
            </Button>
          </Form>
        </div>
      );
    }
  }
}
