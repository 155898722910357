import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import Urls from "../urls/urls";
import "./resetpwd.css";

/**
 * DESCRIPTION : The "Resetpwd" Component display the form that will be
 * used to change the password. This component will be used in case of
 * the user have forgotten his password
 * <br/><br/>
 * STATE :<br/>
 * password : contains the password entered by the user<br/>
 * error : contains the error which will be displayed<br/>
 * issamepassword : contains the error is password and confirm_password are not the same<br/>
 * confirm_password : contains the verification of the confirmation field<br/>
 * get_parameter : contains the get parameter in the url, the uid and the token<br/><br/>
 * RETURN :<br/>
 * Reset password page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */

export default class Resetpwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      error: "",
      issamepassword: "",
      confirm_password: "",
      get_parameter: {
        uid: "",
        token: "",
        fromsettings: false,
      },
    };
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeConfirmPassword =
      this.handleChangeConfirmPassword.bind(this);
  }

  componentDidMount() {
    if (window.sessionStorage.getItem("photo") !== null) {
      window.location.href = Urls.urls_server + "dashboard";
    }
    const queryParams = new URLSearchParams(window.location.search);
    const uid = queryParams.get("uid");
    const token = queryParams.get("token");
    const fromsettings = queryParams.get("fromsettings");
    if (uid === null || token === null) {
      window.location.href = Urls.urls_server;
    }
    this.setState({
      get_parameter: {
        uid: uid,
        token: token,
      },
    });
    if (fromsettings === "true") {
      this.setState({
        get_parameter: {
          fromsettings: true,
        },
      });
    } else {
      this.setState({
        get_parameter: {
          fromsettings: false,
        },
      });
    }
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  /**
   * function : handleChangeConfirmPassword is used to change password confirmation state
   * input : the field which contain the password confirmation
   * output : none
   */
  handleChangeConfirmPassword(event) {
    this.setState({ confirm_password: event.target.value });
    setTimeout(() => {
      if (this.state.confirm_password === this.state.password) {
        this.setState({ issamepassword: "" });
      } else {
        this.setState({
          issamepassword: "Les mots de passe ne sont pas identiques",
        });
      }
    }, 100);
  }

  /**
   * function : handleSubmit is used to reset the password
   * input : none
   * output : none
   */
  handleSubmit = () => {
    if (this.state.get_parameter.fromsettings === true) {
      // If the form is submitted from settings page
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + window.sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          new_password1: this.state.password,
          new_password2: this.state.confirm_password,
        }),
      };

      // Send a POST request to change the password
      fetch(Urls.urls_api + "dj-rest-auth/password/change/", requestOptions)
        .then((res) =>
          res.json().then((data) => {
            // Handle different response scenarios
            if (
              data.detail !== undefined &&
              data.detail === "New password has been saved."
            ) {
              window.location.href = Urls.urls_server + "dashboard";
            } else if (
              data.new_password2 !== undefined &&
              data.new_password2[0] === "The two password fields didn’t match."
            ) {
              this.setState({
                error: "Les mots de passe ne sont pas les mêmes",
              });
            } else if (
              data.new_password2 !== undefined &&
              data.new_password2[0] ===
                "This password is too short. It must contain at least 8 characters."
            ) {
              this.setState({
                error:
                  "Ce mot de passe est trop court. Il doit contenir au moins 8 caractères",
              });
            } else if (
              data.new_password2 !== undefined &&
              data.new_password2[0] ===
                "The password is too similar to the email address."
            ) {
              this.setState({
                error: "Le mot de passe est trop similaire à l'adresse mail",
              });
            } else {
              this.setState({
                error: "Veuillez réessayer avec un autre mot de passe",
              });
            }
          })
        )
        .catch((err) => console.log(err));
    } else {
      // If the form is submitted from password reset confirmation page
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          uid: this.state.get_parameter.uid,
          token: this.state.get_parameter.token,
          new_password1: this.state.password,
          new_password2: this.state.confirm_password,
        }),
      };

      // Send a POST request to confirm password reset
      fetch(
        Urls.urls_api + "dj-rest-auth/password/reset/confirm/",
        requestOptions
      )
        .then((res) =>
          res.json().then((data) => {
            // Handle different response scenarios
            if (
              data.detail === "Password has been reset with the new password."
            ) {
              window.location.href = Urls.urls_server + "connexion";
            } else if (
              data.new_password2 !== undefined &&
              data.new_password2[0] === "The two password fields didn’t match."
            ) {
              this.setState({
                error: "Les mots de passe ne sont pas les mêmes",
              });
            } else if (
              data.new_password2 !== undefined &&
              data.new_password2[0] === "This password is too common.."
            ) {
              this.setState({ error: "Ce mot de passe est trop courant." });
            } else if (
              data.new_password2 !== undefined &&
              data.new_password2[0] ===
                "This password is too short. It must contain at least 8 characters."
            ) {
              this.setState({
                error:
                  "Ce mot de passe est trop court. Il doit contenir au moins 8 caractères",
              });
            } else if (
              data.new_password2 !== undefined &&
              data.new_password2[0] ===
                "The password is too similar to the email address."
            ) {
              this.setState({
                error: "Le mot de passe est trop similaire à l'adresse mail",
              });
            } else if (
              data.new_password2 !== undefined &&
              data.new_password2[0] ===
                "This password is too short. It must contain at least 8 characters."
            ) {
              this.setState({
                error:
                  "Ce mot de passe est trop court. Il doit contenir au moins 8 caractères",
              });
            } else {
              this.setState({
                error: "Veuillez réessayer avec un autre mot de passe",
              });
            }
          })
        )
        .catch((err) => console.log(err));
    }
  };

  render() {
    return (
      <div id="resetpwd">
        <h1 id="titre">Réinitilisation de mot de passe</h1>
        <h3 id="text">Veuillez entrer votre nouveau mot de passe</h3>
        <h3 id="text_error">{this.state.error}</h3>
        <Form id="form">
          <Form.Field id="field">
            <label id="label">Mot de passe</label>
            <input
              type="password"
              placeholder="Mot de passe"
              value={this.state.password}
              onChange={this.handleChangePassword}
            />
          </Form.Field>
          <Form.Field id="field">
            <label id="label">Confirmer le mot de passe</label>
            <input
              type="password"
              placeholder="Confirmer mot de passe"
              value={this.state.confirm_password}
              onChange={this.handleChangeConfirmPassword}
            />
            <h4 id="text_error">{this.state.issamepassword}</h4>
          </Form.Field>
          <Button id="main_button" type="submit" onClick={this.handleSubmit}>
            Réinitialiser le mot de passe
          </Button>
        </Form>
      </div>
    );
  }
}
