import React, { Component } from "react";
import Urls from "../urls/urls";
import "./footer.css";
import { Icon } from "semantic-ui-react";

/**
 * Description : The class "Footer" contains the bottom part of the webapp
 * it contains the copyright<br/><br/>
 * State : <br/>
 * windowWidth : it is the window width used to adapt the footer position<br/><br/>
 * Return :<br/>
 *  The footer of the website
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { windowWidth: window.innerWidth };
  }

  /**
   * this function will be execute as soon as the component will be displayed
   * it is used to update windows width every second
   */
  componentDidMount() {
    setInterval(() => {
      this.setState({ windowWidth: window.innerWidth });
    }, 1000);
    if (this.state.windowWidth < 600) {
      var height = document.getElementById("header_bottom").clientHeight;
      height += 30;
      document.getElementById("footer").style.paddingBottom =
        height.toString() + "px";
    }
  }

  render() {
      return (
        <div id="footer">
          <h5>© 2022 CryptOrganiz . cryptorganiz.com</h5>
          <h5><a href={Urls.urls_server + "mentions"}>Mentions légales</a></h5>
        </div>
      );
  }
}
