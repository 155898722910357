import React, { Component } from "react";
import Urls from "../urls/urls";
import './cancelled.css'

/**
 * DESCRIPTION : The "Cancelled" Component display when the subscription failed.
 * <br/><br/>
 * STATE :<br/>
 * secondes : contains the countdown before redirection<br/><br/>
 * RETURN :<br/>
 * Apiconnexion page<br/>
 * @version 1.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Cancelled extends Component {

    constructor(props) {
        super(props);
        this.state = {
            secondes: 6
        };
    }

    /**
   * this function will be execute as soon as the component will be displayed.
   * it is used to get redirect an unconnected user
   */
    componentDidMount() {
        if (
            window.sessionStorage.getItem("token") === null ||
            window.sessionStorage.getItem("uid") === null
        ) {
            window.location.href = Urls.urls_server;
        }
        setInterval(() => {
            this.setState({ secondes: this.state.secondes - 1 })
        }, 1000);
    }

    /**
   * this function will be execute as soon as the component will be modified.
   * it is used to get redirect an user after the countdown
   */
    componentDidUpdate() {
        if (this.state.secondes === 0) {
            window.location.href = Urls.urls_server + "dashboard";
        }
    }
    render() {
        return (
            <div id="cancelled_message">
                <h1>
                    Changement d'abonnement et paiement annulé, redirection dans {this.state.secondes} secondes
                </h1>
            </div>
        );
    }
}