import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import "./connexion.css";
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import Urls from "../urls/urls";

/**
 * DESCRIPTION : The "Connexion" Component display the first step of login. The user can
 * choose betweend signin with mail adress or google account.
 * If 2fa is on, a mail will be sent to the user after click on button "Connexion"<br/><br/>
 * STATE :<br/>
 * mail : contains the mail entered by the user<br/>
 * password : contains the password entered by the user<br/>
 * confirm : contains the confirm message displayed if the user has verified his email<br/>
 * error : contains the error which will be displayed<br/><br/>
 * RETURN :<br/>
 * login page<br/>
 * @version 2.0.0
 * @author [Lahoucine LAPUNAIRE]
 */
export default class Connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: "",
      error: "",
      password: "",
      confirm: "",
    };
    this.handleChangeMail = this.handleChangeMail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  /**
   * function : handleChangeMail is used to change username state
   * input : the field which contain the username
   * output : none
   */
  handleChangeMail(event) {
    this.setState({ mail: event.target.value.toLowerCase() });
  }

  /**
   * function : handleChangePassword is used to change password state
   * input : field event
   * output : none
   */
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  /**
   * this function will be execute as soon as the component will be displayed.
   * it is used take the get parameter and verify if the user has verified his email
   */
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const confirm = queryParams.get("confirm");
    if (confirm === "true") {
      this.setState({ confirm: "L'email a bien été verifié" });
    }
  }

  /**
   * function : successGoogle is used to connect user with Google auth
   * input : response of Google request
   * output : none
   */
  successGoogle = (response) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        access_token: response.accessToken,
      }),
    };
    fetch(Urls.urls_api + "dj-rest-auth/google/", requestOptions)
      .then((res) =>
        res.json().then((data) => {
          this.setState({confirm:"Connexion reussi, veuillez patienter"})
          if (data.key !== undefined) {
            sessionStorage.setItem("token", data.key);
            sessionStorage.setItem("uid", data.user);
            sessionStorage.setItem("photo", response.profileObj.imageUrl);
            sessionStorage.setItem("email", response.profileObj.email);
            window.location.href = Urls.urls_server + "load";
          } else if (
            data.non_field_errors !== undefined &&
            data.non_field_errors[0] ===
              "User is already registered with this e-mail address."
          ) {
            this.setState({
              error: "Veuillez reéssayer.",
            });
          }
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * function : failureGoogle is used if Google auth fail
   * input : response of Google request
   * output : none
   */
  failureGoogle = (response) => {
    if(response.details === "Cookies are not enabled in current environment."){
      this.setState({ error: "Pour vous connecter avec Google, veuillez autoriser les cookies dans les paramètres de votre navigateur" });
    }
    else{
      this.setState({ error: "Probleme avec la connexion Google." });
    }
    
  };

  /**
   * function : sendMail2fa will send a 2fa email to the mail address
   * input : none
   * output : none
   */
  sendMail2fa = () => {
    const requestOptions = {
      method: "GET",
    };
    fetch(Urls.urls_api + "2fa-email/?email=" + this.state.mail, requestOptions)
      .then((res) =>
        res.json().then((data) => {
          if (data.two_factor_email_auth_enabled === false) {
            localStorage.setItem("email", this.state.mail);
            localStorage.setItem("2fa_state", "false");
            window.location.href = Urls.urls_server + "2fa";
          } else if (data.two_factor_email_auth_enabled === true) {
            localStorage.setItem("email", this.state.mail);
            localStorage.setItem("2fa_state", "true");
            window.location.href = Urls.urls_server + "2fa";
          } else {
            this.setState({
              error: "Cette adresse mail n'est pas enregistrée.",
            });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  /**
   * function : handleSubmit is used to verify if
   * the mail address and the password is correct and if the 2fa is activate
   * input : button event
   * output : none
   */
  handleSubmit(event) {
    // Display a message to indicate that the form submission is in progress
    this.setState({ confirm: "Veuillez patienter" });
  
    // Check if the email or password fields are empty
    if (this.state.mail === "" || this.state.password === "") {
      this.setState({ error: "Veuillez compléter le formulaire." });
      this.setState({ confirm: "" });
    } else {
      // Prepare the request options for the login API call
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.state.mail,
          password: this.state.password,
        }),
      };
  
      // Send a POST request to the login API endpoint
      fetch(Urls.urls_api + "dj-rest-auth/login/", requestOptions)
        .then((res) =>
          res.json().then((data) => {
            // Handle different response scenarios
  
            // Invalid credentials error
            if (
              data.error !== undefined &&
              data.error === "Invalid credentials. Please try again."
            ) {
              this.setState({
                error:
                  "Email ou mot de passe incorrect. Veuillez réessayer.",
              });
              this.setState({ confirm: "" });
            }
  
            // Unable to log in with provided credentials error
            if (
              data.non_field_errors !== undefined &&
              data.non_field_errors[0] ===
                "Unable to log in with provided credentials."
            ) {
              this.setState({
                error:
                  "Email ou mot de passe incorrect. Veuillez réessayer.",
              });
              this.setState({ confirm: "" });
            }
            // Invalid email error
            else if (
              data.error !== undefined &&
              data.error === "Invalid email. User doesn't exist."
            ) {
              this.setState({
                error:
                  "Email ou mot de passe incorrect. Veuillez réessayer.",
              });
              this.setState({ confirm: "" });
            }
            // 2FA (Two-Factor Authentication) enabled
            else if (
              data.error !== undefined &&
              data.error === "Correct credentials, but 2FA is enabled."
            ) {
              localStorage.removeItem("email");
              localStorage.setItem("email", this.state.mail);
              localStorage.setItem("pwd", this.state.password);
              this.sendMail2fa();
              window.location.href = Urls.urls_server + "2fa";
            }
            // Successful login
            else if (data.key !== undefined) {
              localStorage.removeItem("email");
              localStorage.setItem("email", this.state.mail);
              sessionStorage.setItem("token", data.key);
              sessionStorage.setItem("uid", data.user);
              window.location.href = Urls.urls_server + "load";
            } else {
              // Other error scenario
              this.setState({ error: "Veuillez réessayer." });
              this.setState({ confirm: "" });
            }
          })
        )
        .catch((err) => console.log(err));
    }
  }
  

  render() {
    return (
      <div id="connexion">
        <h3 id="green_label">{this.state.confirm}</h3>
        <h1 id="titre">Connexion</h1>
        <h3 id="text">Connectez-vous avec votre adresse mail</h3>
        <h3 id="text_error">{this.state.error}</h3>
        <Form id="form">
          <Form.Field id="field">
            <label id="label">Adresse Mail</label>
            <input
              placeholder="Adresse Mail"
              value={this.state.mail}
              onChange={this.handleChangeMail}
            />
          </Form.Field>
          <Form.Field id="field">
            <label id="label">Mot de passe</label>
            <input
              type="password"
              placeholder="Mot de passe"
              value={this.state.password}
              onChange={this.handleChangePassword}
            />
          </Form.Field>
          <Link id="green_label" to="/forgotpwd">
            <h3 id="text">Mot de passe oublié ?</h3>
          </Link>
          <br />
          <Button id="main_button" type="submit" onClick={this.handleSubmit}>
            Connexion
          </Button>
        </Form>
        <div id="separator_or">
          <hr />
          <br />
          <h4>Ou</h4>
          <br />
          <hr />
        </div>
        <div id="google_connexion">
          <h3 id="text">Connectez vous avec votre compte Google</h3>
          <GoogleLogin
            className="google_button"
            clientId="121644861348-cko3qc5nbids2d53q3qj2sh3rc0rkoa9.apps.googleusercontent.com"
            buttonText="Connexion avec Google"
            onSuccess={this.successGoogle}
            onFailure={this.failureGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
        <div id="separator_or">
          <hr />
          <br />
          <h4>Ou</h4>
          <br />
          <hr />
        </div>
        <Link id="green_label" to="/inscription">
          <h3 id="text">Creer votre compte</h3>
        </Link>
      </div>
    );
  }
}
